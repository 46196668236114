import { call, put } from 'redux-saga/effects';
import { fetchGSBHistoryApi } from '.';
import { GET_GSB_HISTORY_FAILURE, GET_GSB_HISTORY_SUCCESS } from '../constant';
import { GetGsbHistoryAction } from '../../helpers/types';
import { showToast } from '../toaster/action';

export function* getGSBHistorySaga(action: GetGsbHistoryAction): any {
  try {
    const response = yield call(fetchGSBHistoryApi, action?.payload);
    yield put({
      type: GET_GSB_HISTORY_SUCCESS,
      payload: response?.data?.items,
    });
  } catch (error: any) {
    yield put({
      type: GET_GSB_HISTORY_FAILURE,
      payload: error?.message || 'Something went wrong!',
    });
    yield put(
      showToast(
        error?.message || 'Failed to get GSB history!',
        'error',
      ),
    );
  }
}
