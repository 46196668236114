import { styled } from "@mui/material/styles";
import Typography from "@mui/material/Typography";

export const ClickableUrl = styled(Typography)(({ theme }) => ({
  cursor: "pointer",
  display: "inline-block",
  textOverflow: "ellipsis",
  overflow: "hidden",
  whiteSpace: "nowrap",
  maxWidth: 400,
  "&:focus": {
    outline: "none",
  },
}));
