import React from 'react';
import {
  Checkbox,
  FormControlLabel,
  Grid,
  Tooltip,
  Typography,
} from '@mui/material';
import { useSelector } from 'react-redux';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import {
  ButtonsStyle,
  CheckboxStyle,
  FormControlLabelStyle,
} from '../../pages/ThreadsDetails/Style/SingleThreat.style';
import Button from '../Button/Button';
import Loader from '../Loader/Loader';
import GradientButton from '../Button/GradientButton';

const NoteList = ({
  handleNotesChange,
  selectedNoteIds,
  handleApplyNotes,
  showApplyButton,
}: any) => {
  const { getNotesValues, createNotes } = useSelector(
    (state: any) => state.noteComments,
  );

  return (
    <>
      {getNotesValues?.data?.map((item: any, index: number) => (
        <Grid item xs={6} key={index}>
          <Typography variant="body1" sx={{ my: 1, fontWeight: '500' }}>
            {item.name}
          </Typography>
          {item.note_values?.map((note: any, noteIndex: number) =>
            note.deleted_at ? null : (
              <Tooltip title={note.description} arrow key={noteIndex}>
                <FormControlLabel
                  sx={FormControlLabelStyle}
                  control={
                    <Checkbox
                      sx={CheckboxStyle}
                      size="small"
                      checked={selectedNoteIds.includes(note.id)}
                      onChange={() => handleNotesChange(note.id)}
                    />
                  }
                  label={note.name}
                />
              </Tooltip>
            ),
          )}
        </Grid>
      ))}
      {showApplyButton && (
        <ButtonsStyle>
          <GradientButton
            variant="contained"
            disabled={selectedNoteIds.length === 0 || createNotes.loading}
            onClick={handleApplyNotes}>
            {createNotes.loading ? <Loader size={18} /> : 'Apply'}
          </GradientButton>
        </ButtonsStyle>
      )}
    </>
  );
};

export default NoteList;
