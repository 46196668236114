import React, {
  ChangeEvent,
  useCallback,
  useEffect,
  useMemo,
  useState,
} from 'react';
import * as Sentry from '@sentry/react';
import {
  Box,
  Typography,
  Grid,
  InputLabel,
  Pagination,
  Select,
  MenuItem,
  SelectChangeEvent,
} from '@mui/material';
import LanguageIcon from '@mui/icons-material/Language';
import { useDispatch, useSelector } from 'react-redux';
import dayjs from 'dayjs';
import { useAuth0 } from '@auth0/auth0-react';
import { useLocation } from 'react-router-dom';
import FilterCard from './FilterCard';
import {
  changeBulkStatusAction,
  getDomainsListAction,
  getThreatAction,
  resetThreatAction,
  setPageSize,
  setReviewStatusPersistValues,
  setStatusPersistValues,
} from '../../store/threats/action';
import {
  defaultEndDate,
  defaultReviewStatusFilter,
  defaultServiceFilter,
  defaultSortBy,
  defaultSortOrder,
  defaultSourcesFilter,
  defaultStartDate,
  defaultStatusFilter,
  getDateOrDefault,
  getLabelsAndIds,
  PER_PAGE_RECORDS,
  threatsStatus,
} from '../../utils';
import {
  InputContainer,
  ThreatCardWrapper,
  TotalCard,
  TotalText,
  ThreatCardContainer,
  LoaderThreatTable,
} from './Style/Home.style';
import {
  Button,
  SearchBar,
  StatusMenu,
  ConfirmationModal,
  ThreatCardV2,
  ThreatsSkeleton,
  Loader,
} from '../../components';
import {
  BulkThreatDataType,
  ThreatTypes,
  selectedThreatDataType,
} from '../../helpers/types';
import CreateThreatModel from './CreateThreatModel';
import { PAGE_NAME } from '../../utils/analytic';
import { setAnalyticObject } from '../../store/analytic/action';
import { PaginationStyle } from '../ThreadsDetails/Style/SingleThreat.style';
import AddCommonNotesModal from './AddCommonNotesModal';
import {
  getServiceAction,
  setExcludeCompany,
  setCompanyPersistValues,
  setServicesData,
  setSourcesData,
  setPickerStartDate,
  setPickerEndDate,
} from '../../store/company/action';
import { getNotesValuesAction } from '../../store/noteComments/action';
import ThreatTable from '../../components/ThreatTable/ThreatTable';
import ThreatViewToggle from './ThreatViewToggle';
import {
  getUserTokenAction,
  setUserViewPreference,
} from '../../store/user/action';
import { REACT_APP_BASE_URL_WEBSOCKET } from '../../utils/envVar';
import {
  CHANGE_BULK_STATUS_FAILURE,
  CHANGE_STATUSLABEL_SUCCESS,
} from '../../store/constant';
import { showToast } from '../../store/toaster/action';
import { addNotification } from '../../store/notification/action';

const Home = () => {
  const [openConfirmModal, setOpenConfirmModal] = useState<boolean>(false);
  const [selectedThreats, setSelectedThreats] = useState<any>([]); // TODO:
  const [openStatusMenu, setOpenStatusMenu] = useState<null | HTMLElement>(
    null,
  );
  const [currentPage, setCurrentPage] = useState(1);
  const [companyFilterValues, setCompanyFilterValues] = useState<string[]>([]);
  const [searchValue, setSearchValue] = useState<string>('');
  const [selectedBulkStatusLabel, setSelectedBulkStatusLabel] =
    useState<string>('');
  const [openAddThreatModal, setOpenAddThreatModal] = useState<boolean>(false);
  const [hasMoreThreats, setHasMoreThreats] = useState<boolean>(true);
  const [networkData, setNetworkData] = useState<any>({});
  const [openCommonThreatModal, setOpenCommonThreatModal] =
    useState<boolean>(false);
  const [sortBy, setSortBy] = useState<string>(defaultSortBy);
  const [sortOrder, setSortOrder] = useState<string>(defaultSortOrder);
  const [openSortBy, setOpenSortBy] = useState<null | HTMLElement>(null);
  const dispatch = useDispatch();
  const location = useLocation();
  const { user, isAuthenticated, getIdTokenClaims } = useAuth0();

  const { userData } = useSelector((state: any) => state.user);
  const {
    threatList: threats,
    statusPersistValues,
    reviewStatusPersistValues,
    pageSizePersistValues,
    threatsNetworkData,
  } = useSelector((state: any) => state.threats);
  const {
    companyPersistValues,
    companysExclude,
    getServicesData,
    getSourcesData,
    getPickerStartDate,
    getPickerEndDate,
  } = useSelector((state: any) => state.company);
  const currentViewPreference = useSelector(
    (state: any) => state?.user?.viewPreference,
  );

  const selectedCompanyLabels = getLabelsAndIds(companyPersistValues, 'id');
  const selectedStatusLabels = getLabelsAndIds(statusPersistValues, 'label');
  const selectedReviewStatusLabels = getLabelsAndIds(
    reviewStatusPersistValues,
    'label',
  );
  const selectedServiceLabels = getLabelsAndIds(getServicesData, 'label');
  const selectedSourceLabels = getLabelsAndIds(getSourcesData, 'value');
  const selectedCompanyExcludeLabels = getLabelsAndIds(companysExclude, 'id');
  const getThreatActionProps = {
    currentPage,
    searchValue,
    selectedCompanyLabels,
    selectedStatusLabels,
  };

  const isCustomerReviewPage =
    location.pathname === '/threats/customer-review-requests';

  useEffect(() => {
    const params = {
      m: 5,
      d: 0,
    };
    dispatch(getUserTokenAction(params));
  }, []);

  useEffect(() => {
    if (isCustomerReviewPage) {
      dispatch(resetThreatAction());
      dispatch(setReviewStatusPersistValues(reviewStatusPersistValues));
    } else if (location.pathname === '/dashboard') {
      dispatch(setStatusPersistValues(statusPersistValues));
      dispatch(resetThreatAction());
    }
    fetchThreats(sortOrder, sortBy);
  }, [location.pathname, currentPage]);

  const wsMessageHandle = useCallback(
    (event: any) => {
      const wsResponse = JSON.parse(event.data);
      if (wsResponse?.channel === 'threat_status_update') {
        const errorStatus = wsResponse?.data?.error ? true : false;
        const notification = {
          thread_name: wsResponse?.data?.threat_name,
          status: wsResponse?.data?.status || '-',
          userId: wsResponse?.data?.user_id,
          date: wsResponse?.data?.timestamp || '-',
          errorMessage: wsResponse?.data?.error || null,
          isError: errorStatus,
        };

        const isRelevantThreat = threats?.items?.some(
          (threat: { threat_id: string }) =>
            threat.threat_id === wsResponse?.data?.threat_id,
        );

        if (isRelevantThreat) {
          dispatch(addNotification(notification));

          if (wsResponse?.data?.error) {
            dispatch(
              showToast(
                wsResponse?.data?.error || 'Something went wrong',
                'error',
              ),
            );

            dispatch({
              type: CHANGE_BULK_STATUS_FAILURE,
              payload: wsResponse?.data,
            });
          } else {
            dispatch(
              showToast(
                `${wsResponse?.data?.threat_name} status has been changed to ${wsResponse?.data?.status}`,
                'success',
              ),
            );
            dispatch({
              type: CHANGE_STATUSLABEL_SUCCESS,
              payload: wsResponse?.data,
            });
          }
        }
      }

      if (wsResponse?.channel === 'asset_network_data') {
        setNetworkData((prevState: any) => ({
          ...prevState,
          [wsResponse?.data?.asset_id]: {
            ...prevState[wsResponse?.data?.asset_id],
            loader: false,
            ssl: wsResponse?.data?.data?.ssl_certificate ?? null,
            whois: wsResponse?.data?.data?.domain_information ?? null,
            whois_loading: wsResponse?.data?.whois_loading || false,
            ssl_loading: wsResponse?.data?.ssl_loading || false,
            ...(wsResponse?.data?.error && {
              ssl: null,
              whois: null,
              whois_loading: false,
              ssl_loading: false,
            }),
          },
        }));
      }
    },
    [threats.items],
  );

  useEffect(() => {
    let ws: WebSocket | null = null;
    const RECONNECT_INTERVAL = 5000;
    const connect = async () => {
      const token: any = await getIdTokenClaims();

      ws = new WebSocket(
        `${REACT_APP_BASE_URL_WEBSOCKET}?bearer=${token?.__raw}`,
      );

      ws.onmessage = function (event) {
        wsMessageHandle(event);
      };

      ws.onclose = function () {
        setTimeout(connect, RECONNECT_INTERVAL);
      };
    };
    if (isAuthenticated) {
      connect();
    }
  }, [isAuthenticated, getIdTokenClaims]);

  useEffect(() => {
    if (!threats?.loading && threats?.items?.length > 0) {
      setNetworkData((prevNetworkData: any) => {
        const updatedNetworkData = { ...prevNetworkData };

        threats?.items?.forEach((item: { threat_id: string | number }) => {
          if (!updatedNetworkData[item?.threat_id]) {
            updatedNetworkData[item?.threat_id] = {
              ssl: null,
              whois: null,
              ssl_loading: true,
              whois_loading: true,
            };
          }
        });

        return updatedNetworkData;
      });
    }
  }, [threats]);

  const onClickRefreshNetworkData = (threat_id: number) => {
    setNetworkData((prevState: any) => ({
      ...prevState,
      [threat_id]: {
        ...prevState[threat_id],
        whois_loading: true,
        ssl_loading: true,
        ssl: null,
        whois: null,
      },
    }));
  };

  const fetchThreats = (
    sortOrder: string = defaultSortOrder,
    sortBy: string = defaultSortBy,
    size = pageSizePersistValues || PER_PAGE_RECORDS,
  ) => {
    const params = {
      from: getDateOrDefault(getPickerStartDate, defaultStartDate),
      until: getDateOrDefault(getPickerEndDate, defaultEndDate),
      status: isCustomerReviewPage
        ? selectedReviewStatusLabels
        : selectedStatusLabels,
      threat_name: searchValue,
      company_ids: selectedCompanyLabels,
      service: selectedServiceLabels,
      sort: sortBy,
      order: sortOrder,
      page: currentPage,
      size,
      sources: selectedSourceLabels,
      excludeCompanysId: selectedCompanyExcludeLabels,
    };

    dispatch(getThreatAction(params));
  };

  useEffect(() => {
    dispatch(getNotesValuesAction());
    dispatch(getServiceAction());
  }, []);

  const handleSortBy = (
    order: string | undefined,
    sortBy: string | undefined,
  ) => {
    if (order && sortBy) {
      setSortOrder(order);
      setSortBy(sortBy);
      fetchThreats(order, sortBy);
    }
    setOpenSortBy(null);
  };

  const handleUpdatedASCE = () => handleSortBy('ASCENDING', 'updated_at');
  const handleUpdatedDESC = () => handleSortBy('DESCENDING', 'updated_at');
  const handleDetectedASCE = () => handleSortBy('ASCENDING', 'detected_at');
  const handleDetectedDESC = () => handleSortBy('DESCENDING', 'detected_at');

  const handleApplyFilter = () => {
    dispatch(resetThreatAction());
    setCurrentPage(1);
    setHasMoreThreats(true);
    dispatch(
      getThreatAction({
        from: getDateOrDefault(getPickerStartDate, defaultStartDate),
        until: getDateOrDefault(getPickerEndDate, defaultEndDate),
        status: isCustomerReviewPage
          ? selectedReviewStatusLabels
          : selectedStatusLabels,
        threat_name: searchValue,
        company_ids: selectedCompanyLabels,
        service: selectedServiceLabels,
        sort: 'updated_at',
        order: 'DESCENDING',
        page: 1,
        size: pageSizePersistValues || PER_PAGE_RECORDS,
        sources: selectedSourceLabels,
        excludeCompanysId: selectedCompanyExcludeLabels,
      }),
    );
  };
  const handleResetFilter = () => {
    const defaultStatus = isCustomerReviewPage
      ? defaultReviewStatusFilter
      : defaultStatusFilter;

    if (isCustomerReviewPage) {
      dispatch(setReviewStatusPersistValues(defaultReviewStatusFilter));
    } else if (location.pathname === '/dashboard') {
      dispatch(setStatusPersistValues(defaultStatusFilter));
    }

    dispatch(setPickerStartDate(defaultStartDate));
    dispatch(setPickerEndDate(defaultEndDate));
    dispatch(setCompanyPersistValues([]));
    dispatch(setExcludeCompany([]));
    setSearchValue('');
    setCompanyFilterValues([]);
    dispatch(setServicesData(defaultServiceFilter));
    dispatch(setSourcesData(defaultSourcesFilter));
    setCurrentPage(1);
    dispatch(resetThreatAction());
    dispatch(
      getThreatAction({
        from: getDateOrDefault(defaultStartDate, getPickerStartDate),
        until: getDateOrDefault(defaultEndDate, getPickerEndDate),
        status: defaultStatus?.map(status => status?.label),
        company_ids: 0,
        threat_name: '',
        service: defaultServiceFilter?.map(service => service?.label),
        sort: 'updated_at',
        order: 'DESCENDING',
        page: 1,
        size: pageSizePersistValues || PER_PAGE_RECORDS,
        sources: defaultSourcesFilter?.map(sources => sources.value),
      }),
    );
  };

  const handleSelectAll = useCallback(() => {
    if (selectedThreats?.length === threats?.items?.length) {
      setSelectedThreats([]);
    } else {
      const newSelectedThreats = threats?.items?.map((threat: ThreatTypes) => ({
        domain: threat.protected_asset_name,
        threat: threat.threat_name,
        threatId: threat.threat_id,
        companyId: threat.company_id,
        domainId: threat.protected_asset_id,
        currentStatus: threat.identification_status.value,
      }));
      setSelectedThreats(newSelectedThreats || []);
    }
  }, [selectedThreats?.length, threats?.items]);

  const onChangeSatatuses = (
    e: React.SyntheticEvent,
    value: { label: string }[],
  ) => {
    if (isCustomerReviewPage) {
      dispatch(setReviewStatusPersistValues(value));
    } else if (location.pathname === '/dashboard') {
      dispatch(setStatusPersistValues(value));
    }
  };

  const onChangeCompany = (e: React.SyntheticEvent, value: string[]) => {
    dispatch(setCompanyPersistValues(value));
  };

  const onChangeExcludeCompany = (e: React.SyntheticEvent, value: string[]) => {
    dispatch(setExcludeCompany(value));
  };

  const onChangeService = (
    e: React.SyntheticEvent,
    value: { label: string }[],
  ) => {
    dispatch(setServicesData(value));
  };

  const onChangeSource = (
    e: React.SyntheticEvent,
    value: { label: string; value: string }[],
  ) => {
    dispatch(setSourcesData(value));
  };

  const onChangeDate = (value: any) => {
    if (value) {
      const [start, end] = value;
      const formattedStartDate = start || '';
      const formattedEndDate = end || '';

      dispatch(setPickerStartDate(formattedStartDate));
      dispatch(setPickerEndDate(formattedEndDate));
    } else {
      dispatch(setPickerStartDate(''));
      dispatch(setPickerEndDate(''));
    }
  };

  const handleOpenStatusMenu = (event: React.MouseEvent<HTMLElement>) => {
    setOpenStatusMenu(event.currentTarget);
  };

  const handleCloseStatusMenu = () => {
    setOpenStatusMenu(null);
  };

  const handleConfirmModal = (value: string) => {
    setSelectedBulkStatusLabel(value);
    setOpenStatusMenu(null);
    setOpenConfirmModal(true);
  };

  const monitorBulkStatusChange = (selectedThreats: any[]) => {
    const startTime = Date.now();
    const threatIds = selectedThreats.map(threat => threat.threatId);

    const checkStatus = () => {
      const unresolvedThreats = threatIds.filter(threatId => {
        const threat = threats?.items?.find(
          (t: any) => t.threat_id === threatId,
        );
        return threat?.identification_status?.value !== selectedBulkStatusLabel;
      });

      if (unresolvedThreats.length === 0) {
        return;
      }

      const elapsedTime = Date.now() - startTime;
      if (elapsedTime > 45000) {
        // 45 seconds
        Sentry.captureMessage(
          `Bulk status change to ${selectedBulkStatusLabel} taking longer than 30s to resolve. ` +
            `Total threats: ${threatIds.length}, Pending: ${unresolvedThreats.length}. ` +
            `Unresolved threats: ${unresolvedThreats.join(', ')}. ` +
            `Initiated by user: ${
              userData?.data?.Email || userData?.data?.Username || 'unknown'
            }`,
          'warning',
        );

        // Add user context to Sentry
        Sentry.setContext('bulk_action', {
          user_id: userData?.data?.Id,
          user_email: userData?.data?.Email,
          username: userData?.data?.Username,
          total_threats: threatIds.length,
          pending_threats: unresolvedThreats.length,
          target_status: selectedBulkStatusLabel,
          elapsed_time_ms: elapsedTime,
        });

        return;
      }

      setTimeout(checkStatus, 5000);
    };

    checkStatus();
  };

  const updateBulkThreatsStatus = () => {
    setOpenConfirmModal(false);
    const analytic = {
      pageName: PAGE_NAME.HOME_PAGE,
      user: { email: user?.email, name: user?.name },
      isBulkUpdate: true,
    };
    dispatch(setAnalyticObject(analytic));
    const items = selectedThreats.map((item: BulkThreatDataType) => ({
      threat_name: item.threat,
      threatId: item.threatId,
      status: selectedBulkStatusLabel,
      user_id: userData?.data?.Id,
      company_id: item.companyId,
      process: 'w',
      priority: 1,
      protected_asset_id: item.domainId,
      domain: item.domain,
      currentStatus: item.status,
    }));
    const newData = { items };
    dispatch(changeBulkStatusAction(newData));

    // Add monitoring
    monitorBulkStatusChange(selectedThreats);

    setSelectedThreats([]);
  };

  const handleChangeSelectedThreats = useCallback(
    (threatsItem: selectedThreatDataType) => {
      setSelectedThreats((prevThreats: any[]) => {
        const indexOf = prevThreats.findIndex(
          (threat: { threatId: number; domainId: number; companyId: number }) =>
            threat.threatId === threatsItem.threatId &&
            threat.domainId === threatsItem.domainId &&
            threat.companyId === threatsItem.companyId,
        );
        if (indexOf > -1) {
          const newThreats = [...prevThreats];
          newThreats.splice(indexOf, 1);
          return newThreats;
        }
        return [...prevThreats, threatsItem];
      });
    },
    [],
  );

  const handleChangeSearch = (e: ChangeEvent<HTMLInputElement>) => {
    setSearchValue(e.target.value);
  };

  const handleSearchSubmit = () => {
    dispatch(
      getThreatAction({
        from: getDateOrDefault(getPickerStartDate, defaultStartDate),
        until: getDateOrDefault(getPickerEndDate, defaultEndDate),
        status: selectedStatusLabels,
        threat_name: searchValue,
        company_ids: 0,
        service: selectedServiceLabels,
        sort: 'updated_at',
        order: 'DESCENDING',
        page: 1,
        size: pageSizePersistValues,
        sources: selectedSourceLabels,
      }),
    );
  };

  const handleAddThreatModal = () => {
    setOpenAddThreatModal(true);
    dispatch(getDomainsListAction(''));
  };

  const handleClearSearch = () => {
    dispatch(
      getThreatAction({
        from: getDateOrDefault(getPickerStartDate, defaultStartDate),
        until: getDateOrDefault(getPickerEndDate, defaultEndDate),
        status: isCustomerReviewPage
          ? selectedReviewStatusLabels
          : selectedStatusLabels,
        threat_name: '',
        company_ids: selectedCompanyLabels,
        service: selectedServiceLabels,
        sort: sortBy,
        order: sortOrder,
        page: 1,
        size: pageSizePersistValues || PER_PAGE_RECORDS,
        sources: selectedSourceLabels,
        excludeCompanysId: selectedCompanyExcludeLabels,
      }),
    );
    setSearchValue('');
  };

  const count = Math.ceil(threats?.total / pageSizePersistValues);
  const handleChangePage = (event: any, value: number) => {
    setCurrentPage(value);
  };

  const handlePageSizeChange = (event: SelectChangeEvent<string>) => {
    const selectedPageSize = Number(event?.target?.value);
    dispatch(setPageSize(selectedPageSize));
    setCurrentPage(1);
    fetchThreats('DESCENDING', 'updated_at', selectedPageSize);
  };

  const handleViewToggle = (newPreference: string) => {
    dispatch(setUserViewPreference(newPreference));
  };

  const isThreatSelected = useMemo(() => {
    return (threatData: selectedThreatDataType) => {
      const { companyId, domainId, threatId } = threatData;
      const indexOf = selectedThreats.findIndex(
        (threat: selectedThreatDataType) =>
          threat.threatId === threatId &&
          threat.domainId === domainId &&
          threat.companyId === companyId,
      );
      return indexOf > -1;
    };
  }, [selectedThreats]);

  return (
    <Box sx={{ padding: '24px' }}>
      <Grid container spacing={3}>
        <Grid item md={1} sx={{ display: 'flex', width: '100%' }}>
          <TotalCard>
            <Typography>Threats</Typography>
            <Typography sx={TotalText}>
              {threats?.loading ? <Loader size={16} /> : threats?.total}
            </Typography>
          </TotalCard>
        </Grid>
        <Grid item md={11}>
          <FilterCard
            onChangeSatatuses={onChangeSatatuses}
            onChangeCompany={onChangeCompany}
            onChangeService={onChangeService}
            onChangeDate={onChangeDate}
            handleApplyFilter={handleApplyFilter}
            handleResetFilter={handleResetFilter}
            statuesFilterValues={selectedStatusLabels}
            companyFilterValues={companyFilterValues}
            getServicesData={getServicesData}
            getPickerStartDate={getPickerStartDate}
            getPickerEndDate={getPickerEndDate}
            openSortBy={openSortBy}
            setOpenSortBy={setOpenSortBy}
            handleUpdatedASCE={handleUpdatedASCE}
            handleUpdatedDESC={handleUpdatedDESC}
            handleDetectedASCE={handleDetectedASCE}
            handleDetectedDESC={handleDetectedDESC}
            getSourcesData={getSourcesData}
            onChangeSource={onChangeSource}
            onChangeExcludeCompany={onChangeExcludeCompany}
            isCustomerReviewPage={isCustomerReviewPage}
          />
        </Grid>
      </Grid>
      <Box sx={{ width: '100%', mt: 2 }}>
        <Grid sx={{ display: 'flex', justifyContent: 'space-between' }}>
          <Box sx={{ display: 'flex', alignItems: 'center' }}>
            <ThreatViewToggle
              viewPreference={currentViewPreference}
              handleViewToggle={handleViewToggle}
            />
            <SearchBar
              data-testid="threat-search-bar"
              size="small"
              handleChange={handleChangeSearch}
              handleSearch={handleSearchSubmit}
              searchValue={searchValue}
              handleClearSearch={handleClearSearch}
            />
          </Box>
          <Box
            sx={{ display: 'flex', gap: '8px', alignItems: 'center' }}
            id="bulk">
            <Button
              data-testid="add-threat-button"
              variant="contained"
              onClick={handleAddThreatModal}>
              + Add Threat
            </Button>
            {selectedThreats?.length > 0 && (
              <>
                <InputLabel sx={{ color: 'black' }}>
                  {selectedThreats?.length} Selected
                </InputLabel>
                <Button
                  data-testid="select-all-threats-button"
                  variant="outlined"
                  size="medium"
                  label={
                    selectedThreats?.length === threats?.items?.length
                      ? 'Deselected All'
                      : 'Select All'
                  }
                  onClick={handleSelectAll}
                />
                <Button
                  data-testid="change-threats-status-button"
                  variant="contained"
                  size="medium"
                  label="Change Status"
                  onClick={handleOpenStatusMenu}
                />
                <Button
                  data-testid="add-threats-notes-button"
                  variant="outlined"
                  size="medium"
                  label="Add Notes"
                  onClick={() => setOpenCommonThreatModal(true)}
                />
              </>
            )}
          </Box>
        </Grid>
      </Box>

      {currentViewPreference === 'table' ? (
        <ThreatCardContainer data-testid="threats-grid-container">
          {threats?.loading ? (
            <LoaderThreatTable>
              <Loader size={25} />
            </LoaderThreatTable>
          ) : threats?.items?.length === 0 ? (
            <ThreatCardWrapper>No Data Found</ThreatCardWrapper>
          ) : (
            <ThreatTable
              threats={threats}
              handleChangeSelectedThreats={handleChangeSelectedThreats}
              isThreatSelected={isThreatSelected}
              getThreatActionProps={getThreatActionProps}
              threatNetworkData={networkData}
              userData={userData}
            />
          )}
          <PaginationStyle style={{ marginTop: '16px' }}>
            <Select
              data-testid="threats-page-size-select"
              value={String(pageSizePersistValues)}
              onChange={handlePageSizeChange}
              label="Size"
              fullWidth
              style={{ width: '80px', height: '35px', marginLeft: '20px' }}>
              <MenuItem value="10">10</MenuItem>{' '}
              <MenuItem value="25">25</MenuItem>
              <MenuItem value="50">50</MenuItem>
              <MenuItem value="100">100</MenuItem>
            </Select>
            <Pagination
              data-testid="threats-pagination"
              count={count || 0}
              page={currentPage}
              onChange={handleChangePage}
              color="primary"
            />
          </PaginationStyle>
        </ThreatCardContainer>
      ) : (
        <ThreatCardContainer>
          {threats.loading ? (
            <ThreatsSkeleton />
          ) : !threats || !threats.items || threats.items.length === 0 ? (
            <ThreatCardWrapper>No Data Found</ThreatCardWrapper>
          ) : (
            <Grid
              container
              spacing={2}
              columns={{ xs: 12, sm: 12, md: 12, lg: 12, xl: 12 }}>
              {threats?.items?.map((items: ThreatTypes, index: number) => (
                <Grid item xs={12} sm={6} md={4} lg={3} xl={3} key={index}>
                  <ThreatCardV2
                    items={items}
                    handleChangeSelectedThreats={handleChangeSelectedThreats}
                    isThreatSelected={isThreatSelected}
                    getThreatActionProps={getThreatActionProps}
                    threatNetworkData={networkData}
                    onClickRefreshNetworkData={onClickRefreshNetworkData}
                    userData={userData}
                  />
                </Grid>
              ))}
            </Grid>
          )}
          <PaginationStyle style={{ marginTop: '16px' }}>
            <Select
              value={String(pageSizePersistValues)}
              onChange={handlePageSizeChange}
              label="Size"
              fullWidth
              style={{ width: '80px', height: '35px', marginLeft: '20px' }}>
              <MenuItem value="10">10</MenuItem>{' '}
              <MenuItem value="25">25</MenuItem>
              <MenuItem value="50">50</MenuItem>
              <MenuItem value="100">100</MenuItem>
            </Select>
            <Pagination
              count={count || 0}
              page={currentPage}
              onChange={handleChangePage}
              color="primary"
            />
          </PaginationStyle>
        </ThreatCardContainer>
      )}

      <CreateThreatModel
        open={openAddThreatModal}
        handleClose={() => setOpenAddThreatModal(false)}
        userData={userData}
      />
      <AddCommonNotesModal
        open={openCommonThreatModal}
        handleClose={() => setOpenCommonThreatModal(false)}
        selectedThreats={selectedThreats}
      />
      <StatusMenu
        items={threatsStatus}
        openStatusMenu={openStatusMenu}
        closeStatusMenu={handleCloseStatusMenu}
        handleOnClick={handleConfirmModal}
      />
      <ConfirmationModal
        title="Confirm status"
        subTitle="Do you want to change the status?"
        confirmLabel="Update"
        open={openConfirmModal}
        handleClose={() => setOpenConfirmModal(false)}
        handleUpdate={() => updateBulkThreatsStatus()}
      />
    </Box>
  );
};

export default Home;
