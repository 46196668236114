import { GradientButtonProps } from '../../helpers/types';
import { CustomButton } from './GradientButton.styles';

const GradientButton = ({
  variant = 'contained',
  loading,
  label,
  disabled,
  onClick,
  size,
  type,
  children,
  sx,
  ...props
}: GradientButtonProps) => {
  return (
    <CustomButton
      variant={variant}
      disabled={disabled}
      onClick={onClick}
      size={size}
      sx={sx}
      type={type}
      {...props}>
      {loading ? 'Loading...' : label}
      {children}
    </CustomButton>
  );
};

export default GradientButton;
