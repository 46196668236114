import React from 'react';
import { MenuItem, SelectChangeEvent } from '@mui/material';
import { SelectOptionProps } from '../../helpers/types';
import {
  SelectWrapper,
  StyledFormControl,
  StyledSelect,
  StyledInputLabel
} from './SelectOption.styles';

const SelectOption = ({
  label,
  value,
  defaultValue,
  onChange: providedOnChange,
  options,
  width,
  placeholder,
}: SelectOptionProps) => {
  return (
    <SelectWrapper sx={{ width }}>
      <StyledFormControl size="small">
        <StyledInputLabel>{label}</StyledInputLabel>
        <StyledSelect
          value={value}
          label={label}
          onChange={(event) => {
            if (providedOnChange) {
              providedOnChange(event as SelectChangeEvent<string>, null);
            }
          }}
          displayEmpty
          defaultValue={defaultValue}
        >
          <MenuItem disabled value="">
            <em>{placeholder}</em>
          </MenuItem>
          {options?.map((item: any) => (
            <MenuItem sx={{textTransform: 'capitalize'}}
              key={item.value} value={item.value}>
              {item.label}
            </MenuItem>
          ))}
        </StyledSelect>
      </StyledFormControl>
    </SelectWrapper>
  );
};

export default SelectOption;
