import { call, put } from 'redux-saga/effects';
import { showToast } from '../toaster/action';
import {
  CREATE_THREATCOMMENTS_FAILURE,
  CREATE_COMMENTS_SUCCESS,
  CREATE_NOTES_FAILURE,
  CREATE_NOTES_SUCCESS,
  DELETE_COMMENTS_FAILURE,
  DELETE_COMMENTS_SUCCESS,
  DELETE_NOTES_FAILURE,
  DELETE_NOTES_SUCCESS,
  GET_NOTES_VALUES_FAILURE,
  GET_NOTES_VALUES_SUCCESS,
  GET_COMMENTS_FAILURE,
  GET_COMMENTS_SUCCESS,
  GET_NOTES_ID_FAILURE,
  GET_NOTES_ID_SUCCESS,
  UPDATE_COMMENTS_FAILURE,
  UPDATE_COMMENTS_SUCCESS,
} from '../constant';
import {
  createCommentsApi,
  createNotesApi,
  deleteCommentsApi,
  deleteNoteApi,
  getNotesValuesApi,
  getCommentsApi,
  getNoteIdApi,
  updateCommentsApi,
} from '.';

export function* getNotesValuesSaga(action: any): any {
  try {
    const response = yield call(getNotesValuesApi);
    yield put({
      type: GET_NOTES_VALUES_SUCCESS,
      payload: response?.data,
    });
  } catch (error: any) {
    yield put({
      type: GET_NOTES_VALUES_FAILURE,
      payload: error?.message || 'Something went wrong!',
    });
    yield put(
      showToast(error?.message || 'Failed to get notes value!', 'error'),
    );
  }
}

export function* getNoteIdSaga(action: any): any {
  try {
    const response = yield call(getNoteIdApi, action?.payload);
    yield put({
      type: GET_NOTES_ID_SUCCESS,
      payload: response?.data,
    });
  } catch (error: any) {
    yield put({
      type: GET_NOTES_ID_FAILURE,
      payload: error?.message || 'Something went wrong!',
    });
    yield put(showToast(error?.message || 'Failed to get notes Id!', 'error'));
  }
}

export function* createNotesSaga(action: any): any {
  try {
    const response = yield call(createNotesApi, action?.payload);
    yield put(
      showToast(
        response?.data?.message || 'Notes successfully created',
        'success',
      ),
    );
    yield put({
      type: CREATE_NOTES_SUCCESS,
      payload: response?.data,
    });
  } catch (error: any) {
    yield put(
      showToast(
        error?.message ||
          error?.response?.data?.detail[0].msg ||
          error?.response?.data?.detail ||
          'An error occurred while creating notes',
        'error',
      ),
    );
    yield put({
      type: CREATE_NOTES_FAILURE,
      payload: error?.message || 'Something went wrong!',
    });
  }
}

export function* deleteNoteSaga(action: any): any {
  try {
    const response = yield call(deleteNoteApi, action?.payload);
    yield put({
      type: DELETE_NOTES_SUCCESS,
      payload: response.data,
    });
    const getThreatNotesRes = yield call(getNoteIdApi, {
      domain_id: action.payload?.domain_id,
      threat_id: action.payload?.threat_id,
      page: 1,
      size: 50,
    });
    yield put({
      type: GET_NOTES_ID_SUCCESS,
      payload: getThreatNotesRes?.data,
    });
  } catch (error: any) {
    yield put({
      type: DELETE_NOTES_FAILURE,
      payload: error?.message || 'Something went wrong!',
    });
    yield put(showToast(error?.message || 'Fail to delete note', 'error'));
  }
}

export function* getCommentsSaga(action: any): any {
  try {
    const response = yield call(getCommentsApi, action?.payload);
    yield put({
      type: GET_COMMENTS_SUCCESS,
      payload: response?.data,
    });
  } catch (error: any) {
    yield put({
      type: GET_COMMENTS_FAILURE,
      payload: error?.message || 'Something went wrong!',
    });
    yield put(showToast(error?.message || 'Fail to get comment', 'error'));
  }
}

export function* createCommentsSaga(action: any): any {
  try {
    const response = yield call(createCommentsApi, action?.payload);
    const getCommentsPayload = action?.payload?.items?.map((item: any) => item);
    const getCommentsResponse = yield call(getCommentsApi, {
      comments: getCommentsPayload[0]?.comments,
      domain_id: getCommentsPayload[0]?.domain_id,
      threat_id: getCommentsPayload[0]?.threat_id,
      is_public: getCommentsPayload[0]?.is_public,
    });
    yield put(showToast('Comments successfully created', 'success'));
    yield put({
      type: CREATE_COMMENTS_SUCCESS,
      payload: response?.data,
    });
    yield put({
      type: GET_COMMENTS_SUCCESS,
      payload: getCommentsResponse.data,
    });
  } catch (error: any) {
    yield put(
      showToast(
        error.message ||
          error.response.data.detail[0].msg ||
          error.response.data.detail ||
          'An error occurred while creating comments',
        'error',
      ),
    );
    yield put({
      type: CREATE_THREATCOMMENTS_FAILURE,
      payload:
        error?.response?.data?.detail[0]?.msg ||
        error?.response?.data?.detail ||
        'Something went wrong!',
    });
    yield put(
      showToast(error?.message || 'Fail to create threat comment', 'error'),
    );
  }
}

export function* updateCommentsSaga(action: any): any {
  try {
    const response = yield call(updateCommentsApi, {
      comments: action?.payload?.comments,
      comment_id: action?.payload?.comment_id,
      is_public: action?.payload?.is_public,
    });
    const getCommentsResponse = yield call(getCommentsApi, {
      domain_id: action?.payload?.domain_id,
      threat_id: action?.payload?.threat_id,
    });
    yield put({
      type: UPDATE_COMMENTS_SUCCESS,
      payload: response?.data,
    });
    yield put({
      type: GET_COMMENTS_SUCCESS,
      payload: getCommentsResponse?.data,
    });
  } catch (error: any) {
    yield put(
      showToast(
        error.message ||
          error.response.data.detail[0].msg ||
          error.response.data.detail ||
          'An error occurred while updating comments',
        'error',
      ),
    );
    yield put({
      type: UPDATE_COMMENTS_FAILURE,
      payload: error?.message || 'Something went wrong!',
    });
    yield put(
      showToast(error?.message || 'Fail to update threat comment', 'error'),
    );
  }
}

export function* deleteCommentsSaga(action: any): any {
  try {
    const response = yield call(deleteCommentsApi, action?.payload);
    yield put({
      type: DELETE_COMMENTS_SUCCESS,
      payload: response?.data,
    });
    const getCommentsRes = yield call(getCommentsApi, {
      domain_id: action?.payload?.domain_id,
      threat_id: action?.payload?.threat_id,
    });
    yield put({
      type: GET_COMMENTS_SUCCESS,
      payload: getCommentsRes?.data,
    });
  } catch (error: any) {
    yield put({
      type: DELETE_COMMENTS_FAILURE,
      payload: error?.message || 'Something went wrong!',
    });
    yield put(showToast(error?.message || 'Fail to delete comment', 'error'));
  }
}
