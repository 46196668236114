import React, { useCallback, useEffect, useMemo } from 'react';
import { Grid, MenuItem } from '@mui/material';
import { useDispatch, useSelector } from 'react-redux';
import FilterListIcon from '@mui/icons-material/FilterList';
import { StyledFilterCard, StyledControlsBox, StyledSortMenu } from './Style/FilterCard.styles';
import {
  Button,
  DatePickers,
  Dropdown,
} from '../../components';
import { getCompanyAction } from '../../store/company/action';
import { CompaniesType, ServicesType } from '../../helpers/types';
import { getStatusAction } from '../../store/threats/action';
import {
  defaultEndDate,
  defaultStartDate,
  disableAfterToday,
  getDateOrDefault,
  isDisabledStatus,
  SourcesData,
} from '../../utils';

const FilterCard = ({
  onChangeSatatuses,
  onChangeCompany,
  onChangeDate,
  handleApplyFilter,
  handleResetFilter,
  getPickerStartDate,
  getPickerEndDate,
  openSortBy,
  setOpenSortBy,
  handleUpdatedASCE,
  handleUpdatedDESC,
  handleDetectedASCE,
  handleDetectedDESC,
  onChangeService,
  getServicesData,
  onChangeSource,
  getSourcesData,
  onChangeExcludeCompany,
  isCustomerReviewPage,
}: any) => {
  const dispatch = useDispatch();
  const { getStatus, statusPersistValues, reviewStatusPersistValues } =
    useSelector((state: any) => state.threats);
  const { getCompany, companyPersistValues, getService, companysExclude } =
    useSelector((state: any) => state.company);

  useEffect(() => {
    dispatch(getCompanyAction());
    dispatch(getStatusAction());
  }, []);

  const handleCloseSortBy = () => {
    setOpenSortBy(null);
  };

  const companiesData = useCallback(
    getCompany?.data?.map((company: CompaniesType) => ({
      label: company.Name,
      id: company.Id,
    })),
    [getCompany?.data],
  );
  const statusesData = useMemo(() => {
    const statuses = getStatus?.data?.items?.map(
      (status: { name: string; id: string }) => ({
        label: status.name,
        id: status.id,
        disabled: isDisabledStatus(status.name, isCustomerReviewPage),
      }),
    );

    return statuses?.sort(
      (a: { disabled: boolean }, b: { disabled: boolean }) =>
        Number(a.disabled) - Number(b.disabled),
    );
  }, [getStatus?.data?.items, isCustomerReviewPage]);

  const servicesData = useCallback(
    getService?.data?.items?.map((service: ServicesType) => ({
      label: service?.name,
      id: service?.id,
    })),
    [getService?.data?.items],
  );

  return (
    <StyledFilterCard data-testid="filter-card-container">
      <Grid container rowSpacing={1.5} columnSpacing={{ xs: 1, sm: 2, md: 3 }}>
        <Grid item xs={4.5} id="status">
          <Dropdown
            dataTestid="status-dropdown"
            items={statusesData || []}
            size="small"
            placeholder="Status"
            label="Status"
            onChange={onChangeSatatuses}
            selectedValues={
              isCustomerReviewPage
                ? reviewStatusPersistValues
                : statusPersistValues
            }
            multiple={true}
          />
        </Grid>
        <Grid item xs={4.5} id="services">
          <Dropdown
            dataTestid="service-dropdown"
            items={servicesData || []}
            size="small"
            placeholder="Service"
            label="Service"
            onChange={onChangeService}
            selectedValues={getServicesData}
            multiple={true}
          />
        </Grid>
        <Grid item xs={3} id="date-pickers"> 
          <DatePickers
            size="small"
            onChange={onChangeDate}
            defaultStartDate={getDateOrDefault(
              getPickerStartDate,
              defaultStartDate,
            )}
            defaultEndDate={getDateOrDefault(getPickerEndDate, defaultEndDate)}
            disableAfterToday={disableAfterToday}
          />
        </Grid>
        <Grid item xs={3} id="sources">
          <Dropdown
            dataTestid="sources-dropdown"
            items={SourcesData || []}
            size="small"
            placeholder="Sources"
            label="Sources"
            onChange={onChangeSource}
            selectedValues={getSourcesData}
            multiple={true}
          />
        </Grid>
        <Grid item xs={3} id="companies">
          <Dropdown
            dataTestid="company-dropdown"
            items={companiesData}
            size="small"
            placeholder="Company"
            label="Company"
            onChange={onChangeCompany}
            selectedValues={companyPersistValues}
            multiple={true}
          />
        </Grid>
        <Grid item xs={3} id="exclude-companies">
          <Dropdown
            dataTestid="exclude-company-dropdown"
            items={companiesData}
            size="small"
            placeholder="Exclude Company"
            label="Exclude Company"
            onChange={onChangeExcludeCompany}
            selectedValues={companysExclude}
            multiple={true}
          />
        </Grid>
        <Grid item xs={3} id="controls">
          <StyledControlsBox>
          <Button
              data-testid="sort-button"
              onClick={event => setOpenSortBy(event.currentTarget)}
              variant="contained">
              <FilterListIcon />
              Sort By
          </Button>
            <StyledSortMenu
              data-testid="sort-menu"
              anchorEl={openSortBy}
              anchorOrigin={{
                vertical: 'top',
                horizontal: 'right',
              }}
              keepMounted
              transformOrigin={{
                vertical: 'top',
                horizontal: 'right',
              }}
              open={Boolean(openSortBy)}
              onClose={handleCloseSortBy}>
              <MenuItem onClick={handleUpdatedASCE}>Update date <span> (older first)</span></MenuItem>
              <MenuItem onClick={handleUpdatedDESC}>Update date <span> (newer first)</span></MenuItem>
              <MenuItem onClick={handleDetectedASCE}>Detection date<span> (older first)</span></MenuItem>
              <MenuItem onClick={handleDetectedDESC}>Detection date <span> (newer first)</span></MenuItem>
            </StyledSortMenu>
            <Button 
              data-testid="apply-filter-button" 
              variant="contained" 
              onClick={handleApplyFilter}
            >
              Apply
            </Button>
            <Button 
              data-testid="reset-filter-button" 
              onClick={handleResetFilter} 
              variant="outlined"
            >
              Reset
            </Button>
          </StyledControlsBox>
        </Grid>
      </Grid>
    </StyledFilterCard>
  );
};

export default FilterCard;
