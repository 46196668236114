import { call, put } from 'redux-saga/effects';
import { getActiveDefenceApi } from '.';
import {
  GET_ACTIVE_DEFENCE_FAILURE,
  GET_ACTIVE_DEFENCE_SUCCESS,
} from '../constant';
import { showToast } from '../toaster/action';
import { GetActiveDefenceActiontype } from '../../helpers/types';

export function* getActiveDefenceSaga(action: GetActiveDefenceActiontype): any {
  try {
    const response = yield call(getActiveDefenceApi, action?.payload);
    yield put({
      type: GET_ACTIVE_DEFENCE_SUCCESS,
      payload: response?.data,
    });
    
  } catch (error: any) {
    yield put(
      showToast(
        error?.message || 'Submission Active Defence failed, please try again.',
        'error',
      ),
    );
    yield put({
      type: GET_ACTIVE_DEFENCE_FAILURE,
      payload: error?.response?.data || 'Something went wrong!',
    });
  }
}
