import { Box, Stack, styled } from '@mui/material';
import SyncIcon from '@mui/icons-material/Sync';
export const CardContent = styled(Box)({
  padding: '8px 0',
  whiteSpace: 'nowrap',
});

export const CardWrapper = {
  boxShadow: '0px 18px 32px 16px rgba(193, 193, 193, 0.16)',
  borderRadius: '16px',
  padding: '14px',
  position: 'relative',

  '&:hover': {
    backgroundColor: '#F9FAFB',
  },
};

export const LoaderWrapper = {
  border: '1px solid #e2e2e2',
  borderRadius: '10px',
  padding: '14px',
  pointerEvents: 'none',
  opacity: 0.4,
};

export const CardTitle = {
  '& .MuiFormControlLabel-label': { fontWeight: 600, padding: '0 4px' },
  '& .MuiCheckbox-root': { paddingY: 0 },
};

export const TableTitle = {
  '& .MuiFormControlLabel-label': { padding: '0 4px' },
  '& .MuiCheckbox-root': { paddingY: 0 },
};

export const StatusWrapper = styled(Box)({
  display: 'flex',
  justifyContent: 'space-between',
});

export const CardActions = styled(Box)({
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center',
  padding: '0 20px',
});

export const SyncIconWrapper = styled(Box)({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'space-between',
  marginBottom: '8px',
});

export const ThreatDetails = {
  textDecoration: 'none',
  color: 'black',
};

export const DomainInformation = styled('p')({
  display: 'flex',
  fontSize: '13px',
  color: '#000000',
  width: '100%',
  gap: '5px',
  marginTop: '0',
});

export const CtiScore = styled('p')({
  display: 'flex',
  fontSize: '13px',
  color: '#000000',
  width: '100%',
  gap: '5px',
  marginTop: '0',
});

export const Span = styled('span')({
  fontStyle: 'italic',
  color: '#aaa',
});

export const StackContainer = styled(Stack)({
  fontSize: '13px',
  margin: '8px 0 0',
  color: '#000000',
  display: 'flex',
});
