import { useCallback, useState } from 'react';
import {
  Box,
  Dialog,
  DialogContent,
  DialogTitle,
  IconButton,
  Typography,
  styled,
} from '@mui/material';
import { useParams } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import CloseIcon from '@mui/icons-material/Close';
import { Button, Dropdown, GradientButton } from '../../components';
import { ButtonWrapper } from '../CompaniesList/Style/CompaniesList.style';
import { CloseIconStyle } from './Tabs/Style/Tabs.style';
import { AddChildModalType, CompaniesType } from '../../helpers/types';
import { addChildCompanyAction } from '../../store/company/action';

const AddChildDialog = styled(Dialog)(({ theme }) => ({
  '& .MuiDialogContent-root': {
    padding: theme.spacing(3, 5),
  },
  '& .MuiDialog-paper': {
    width: '600px',
  },
}));

const AddChildModal = ({ handleClose, open }: AddChildModalType) => {
  const [selectedCompany, setSelectedCompany] = useState<any>(null);

  const dispatch = useDispatch();
  const { id } = useParams();
  const { getCompany } = useSelector((state: any) => state.company);

  const handleCompanyChange = (event: any, value: any) => {
    setSelectedCompany(value);
  };

  const handleAddChildCompany = () => {
    dispatch(
      addChildCompanyAction({ companyId: selectedCompany.id, parentId: id }),
    );
    handleClose();
    setSelectedCompany(null);
  };

  const companiesData = useCallback(
    getCompany?.data?.map((company: CompaniesType) => ({
      label: company.Name,
      id: company.Id,
    })),
    [getCompany?.data],
  );

  return (
    <>
      <AddChildDialog onClose={handleClose} open={open}>
        <DialogTitle sx={{ m: 0, px: 3, fontWeight: 600 }}>
          Add Child
        </DialogTitle>
        <IconButton onClick={handleClose} sx={CloseIconStyle}>
          <CloseIcon />
        </IconButton>
        <DialogContent dividers>
          <Box sx={{ display: 'flex', mt: 2 }}>
            <Typography gutterBottom sx={{ color: 'black', width: '30%' }}>
              Child *
            </Typography>
            <Dropdown
              items={companiesData}
              size="small"
              placeholder="company list"
              label="company list"
              onChange={handleCompanyChange}
              selectedValues={selectedCompany}
              multiple={false}
            />
          </Box>
        </DialogContent>
        <ButtonWrapper sx={{ mt: 2 }}>
          <Button variant="outlined" onClick={handleClose} color="secondary">
            Cancel
          </Button>
          <GradientButton
            data-testid="submit-child-company-button"
            variant="contained"
            type="submit"
            onClick={handleAddChildCompany}>
            Submit
          </GradientButton>
        </ButtonWrapper>
      </AddChildDialog>
    </>
  );
};

export default AddChildModal;
