import React from 'react';
import { useDispatch } from 'react-redux';
import { styled, Paper, Typography, IconButton } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import { deleteCommentsAction } from '../../store/noteComments/action';

const CommentsCard = styled(Paper)({
  padding: '8px',
  marginBottom: '8px',
  backgroundColor: 'white',
  position: 'relative',
});

const CloseCommentsIcon = {
  color: (theme: any) => theme.palette.grey[500],
  position: 'absolute',
  top: 0,
  right: 0,
};

const ThreatCommentsListCard = ({ comments }: any) => {
  const dispatch = useDispatch();

  const handleDeleteComments = () => {
    dispatch(
      deleteCommentsAction({
        comment_id: comments.id,
        domain_id: comments.domain_id,
        threat_id: comments.threat_id,
      }),
    );
  };

  return (
    <CommentsCard data-testid="threat-comment-card">
      <Typography>{comments.comments}</Typography>
      <IconButton 
        data-testid="delete-comment-button"
        sx={CloseCommentsIcon} 
        onClick={handleDeleteComments}>
        <CloseIcon />
      </IconButton>
    </CommentsCard>
  );
};

export default ThreatCommentsListCard;
