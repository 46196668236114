import { CreateTakedownType, EnrichTakedownType } from '../../helpers/types';
import { apiClient, apiClientTakeDown } from '../../services/apiClient';

export const getTakeDownListApi = async (payload: any) => {
  let url = `v2/takedown/?threat_domain=${payload.threat_domain}&target_domain=${payload.target_domain}&page=${payload.page}&size=${payload.size}&takedown_type=${payload.takedown_type}`;

  if (payload.since && payload.until) {
    url += `&since=${payload.since}&until=${payload.until}`;
  }

  if (typeof payload.status_names === 'string') {
    url += `&status_names=${payload.status_names}`;
  }

  if (payload.company_id && payload.company_id.length > 0) {
    const companyIdsQuery = payload.company_id
      .map((id: string) => `company_ids=${id}`)
      .join('&');
    url += `&${companyIdsQuery}`;
  }

  const config = {
    method: 'GET',
    url: url,
  };
  const res = await apiClientTakeDown(config);
  return res;
};

export const createTakedownApi = async (data: CreateTakedownType) => {
  const config = {
    method: 'POST',
    url: `v1/takedown/`,
    data,
  };
  const res = await apiClientTakeDown(config);
  return res;
};

export const completeTakedownApi = async (data: { id: string }) => {
  const config = {
    method: 'POST',
    url: `v2/takedown/${data.id}/status`,
    data,
  };
  const res = await apiClientTakeDown(config);
  return res;
};

export const getHubSportIdApi = async (ThreatName: string) => {
  const config = {
    method: 'GET',
    url: `domain/getwi?d=${ThreatName}`,
  };
  const res = await apiClient(config);
  return res;
};

export const getTakedownDetailApi = async (payload: any) => {
  const config = {
    method: 'GET',
    url: `v2/assets/${payload.asset_id}/threats/${payload.threat_id}/takedowns`,
  };
  return await apiClientTakeDown(config);
};

export const getTakedownStatusApi = async (payload?: {
  takedown_type: string;
}) => {
  let url = `v2/takedown/statuses`;
  if (payload?.takedown_type) {
    url += `?takedown_type=${payload.takedown_type}`;
  }
  const config = {
    method: 'GET',
    url,
  };
  const res = await apiClientTakeDown(config);
  return res;
};

export const takedownStatusHistoryApi = async (payload: {
  takedown_id: string;
}) => {
  const config = {
    method: 'GET',
    url: `v2/takedown/${payload.takedown_id}/statuses`,
  };
  const res = await apiClientTakeDown(config);
  return res;
};

export const updateEnrichTakedownApi = async (data: EnrichTakedownType) => {
  const config = {
    method: 'POST',
    url: `v1/takedown/enrich_takedown_manually/?takedown_id=${data.takedown_id}&abuse_email=${data.abuse_email}&registrar_name=${data.registrar_name}&A_record=${data.A_record}&MX_record=${data.MX_record}`,
    data,
  };
  const res = await apiClientTakeDown(config);
  return res;
};

export const getTakedownListTypesApi = async () => {
  const config = {
    method: 'GET',
    url: `v1/takedown/takedown_types/`,
  };
  const res = await apiClientTakeDown(config);
  return res;
};

export const getTakeDownCancellationReasons = async () => {
  const config = {
    method: 'GET',
    url: `v2/takedown/cancellation_reasons`,
  };
  const res = await apiClientTakeDown(config);
  return res;
};
