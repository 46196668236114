import React from 'react';
import { Icon, Menu, MenuItem, Typography } from '@mui/material';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import OfflinePinIcon from '@mui/icons-material/OfflinePin';
import CancelIcon from '@mui/icons-material/Cancel';
import PlaylistAddCircleIcon from '@mui/icons-material/PlaylistAddCircle';

import { StatusMenuProps } from '../../helpers/types';
import { theme } from '../../styles/theme';


type StatusType = 'discarded' | 'confirmed' | 'trademark' | 'owned' | 'detected';

type StatusValueType = {
  label: string;
  icon: React.ComponentType | string;
  color: string;
};

type ValuesMapType = {
  [K in StatusType]: StatusValueType;
};

// Function to map the values, didn't want to touch the original array threatsStatus in Home in case
// something else depends on it. This needs to be refactored.
const calcLabelAndIconFromStatus = (status: StatusType): StatusValueType => {
  const valuesMap: ValuesMapType = {
    discarded: {
      label: 'Discard',
      icon: CancelIcon,
      color: ''
    },
    confirmed: {
      label: 'Confirm',
      icon: CheckCircleIcon,
      color: ''
    },
    trademark: {
      label: 'Trademark',
      icon: OfflinePinIcon,
      color: ''
    },
    owned: {
      label: 'Owned',
      icon: PlaylistAddCircleIcon,
      color: ''
    },
    detected: {
      label: 'Detected',
      icon: '',
      color: ''
    },
  };
  return valuesMap[status];
}


const StatusMenu = ({
  openStatusMenu,
  closeStatusMenu,
  items,
  handleOnClick,
}: StatusMenuProps) => {
  return (
    <Menu
      sx={{ mt: '45px' }}
      anchorEl={openStatusMenu}
      anchorOrigin={{
        vertical: 'top',
        horizontal: 'right',
      }}
      keepMounted
      transformOrigin={{
        vertical: 'top',
        horizontal: 'right',
      }}
      open={Boolean(openStatusMenu)}
      onClose={closeStatusMenu}>
      {items?.map((item: string) => {
        const mappedItem = calcLabelAndIconFromStatus(item as StatusType);
        return (
          <MenuItem key={item} onClick={() => handleOnClick(item)}
            style={{minHeight: '50px', color: mappedItem.color}}>
            {typeof mappedItem.icon === 'string' ? (
              <Icon sx={{}}>{mappedItem.icon}</Icon>
            ) : (
              mappedItem.icon && React.createElement(mappedItem.icon)
            )}
            <Typography textAlign="center" sx={{ ml: 1 }}>{mappedItem.label}</Typography>
          </MenuItem>
        )
      })}
    </Menu>
  );
};

export default StatusMenu;
