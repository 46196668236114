import { styled } from '@mui/material/styles';
import { default as SimpleButton } from '@mui/material/Button';
import { GradientButtonProps } from '../../helpers/types';
import { colors } from '../../styles/theme';


// is this repeated? there is another CustomButton in Button.tsx. We need to keep the other one
export const CustomButton = styled(SimpleButton)<GradientButtonProps>(
  ({ disabled, variant, theme }) => ({
    color: disabled ? '#A9A9A9' : theme.palette.text.primary,
    background: disabled
      ? '#D3D3D3'
      : colors.primary.main,
    textTransform: 'capitalize',
    width: 'fit-content',
    height: 'fit-content',
    '&.MuiButton-root': {
      padding: '5px 13px',
    },
    '&:hover': {
      background: disabled
        ? '#D3D3D3'
        : '#018184',
    },
  }),
);
