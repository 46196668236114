import React, { useEffect, useState } from 'react';
import { Box, Typography, Modal } from '@mui/material';
import { useDispatch, useSelector } from 'react-redux';
import {
  CreateThreatModelType,
  NetworkThreat,
  RegionCode,
  Label,
  Platform,
  SubmitData,
} from '../../helpers/types';
import { Button, GradientButton, Input, SelectOption } from '../../components';
import {
  fetchLabels,
  fetchNetworkThreats,
  fetchPlatforms,
  fetchRegionCodes,
  submitToGsbAction,
} from '../../store/singleThreat/action';
import { buttonContainerStyles, modalStyles } from './Style/SingleThreat.style';
import { showToast } from '../../store/toaster/action';

const GSBModel = ({
  open,
  handleClose,
  singleThreat,
  threatDetail,
}: CreateThreatModelType) => {
  const [threatName, setThreatName] = useState<string>('');
  const [regionCode, setRegionCode] = useState<string>('');
  const [platform, setPlatform] = useState<string>('');
  const [threatType, setThreatType] = useState<string>('');
  const [label, setLabel] = useState<string>('');

  const { protected_asset_id: asset_id, threat_id } = singleThreat || {};
  const dispatch = useDispatch();

  const { networkThreats, regionCodes, labels, platforms } = useSelector(
    (state: any) => ({
      networkThreats: state.singleThreat.networkThreats,
      regionCodes: state.singleThreat.regionCodes,
      labels: state.singleThreat.labels,
      platforms: state.singleThreat.platforms,
    }),
  );

  const mappedRegionCodes: { label: string; value: string }[] =
    regionCodes?.data?.items?.map(({ name, code }: RegionCode) => ({
      label: name,
      value: code,
    })) || [];

  const mappedLabels: { label: string; value: string }[] =
    labels?.data?.map(({ name, value }: Label) => ({
      label: name,
      value,
    })) || [];

  const mappedPlatforms: { label: string; value: string }[] =
    platforms?.data?.map(({ name, value }: Platform) => ({
      label: name,
      value,
    })) || [];

  const threatOptions: { label: string; value: string }[] =
    networkThreats?.data?.map((threat: NetworkThreat) => ({
      label: threat.name,
      value: threat.value,
    })) || [];

  useEffect(() => {
    if (asset_id && threat_id) {
      dispatch(fetchRegionCodes());
      dispatch(fetchPlatforms());
      dispatch(fetchNetworkThreats());
      dispatch(fetchLabels());
    }
  }, [dispatch, asset_id, threat_id]);

  const handleCreateThreat = () => {
    if (!regionCode || !threatName || !platform || !threatType || !label) {
      dispatch(showToast('Please fill in all the fields!', 'error'));
      return;
    }
    const data: SubmitData = {
      url: `https://${threatDetail?.threat_name || 'defaultUrl'}`,
      region_codes: regionCode,
      platform,
      threat_type: threatType,
      comments: threatName,
      labels: [label],
    };
    dispatch(submitToGsbAction(data));
    handleClose();
    resetForm();
  };

  const resetForm = () => {
    setThreatName('');
    setRegionCode('');
    setPlatform('');
    setThreatType('');
    setLabel('');
  };

  const handleCancel = () => {
    resetForm();
    handleClose();
  };

  return (
    <>
      <Modal open={open} onClose={handleClose}>
        <Box sx={modalStyles}>
          <Typography
            variant="h6"
            component="h2"
            sx={{ mb: 2, fontWeight: 'bold' }}>
            GSB History
          </Typography>

          <SelectOption
            data-testid="gsb-region-code-select"
            label="Region Code"
            size="small"
            onChange={event => setRegionCode(event.target.value)}
            value={regionCode}
            options={mappedRegionCodes}
            width="100%"
          />

          <SelectOption
            label="Platform"
            size="small"
            onChange={event => setPlatform(event.target.value)}
            value={platform}
            options={mappedPlatforms}
            width="100%"
          />

          <SelectOption
            label="Threat Type"
            size="small"
            onChange={event => setThreatType(event.target.value)}
            value={threatType}
            options={threatOptions}
            width="100%"
          />

          <SelectOption
            label="Labels"
            size="small"
            onChange={event => setLabel(event.target.value)}
            value={label}
            options={mappedLabels}
            width="100%"
          />

          <Input
            size="small"
            placeholder="Write a comment"
            label="Comments"
            value={threatName}
            sx={{ width: '100%', mt: 2, bgcolor: '#f7f7f7', borderRadius: 1 }}
            onChange={e => setThreatName(e.target.value)}
          />

          <Box sx={buttonContainerStyles}>
            <Button
              onClick={handleCancel}
              variant="outlined"
              color="secondary"
              sx={{
                flex: 1,
                borderRadius: 1,
                '&:hover': { borderColor: '#ff4081' },
              }}>
              Cancel
            </Button>
            <GradientButton
              onClick={handleCreateThreat}
              label="Update"
              type="submit"
              sx={{ flex: 1, borderRadius: 1 }}
            />
          </Box>
        </Box>
      </Modal>
    </>
  );
};

export default GSBModel;
