import { styled } from '@mui/material/styles';
import { default as SimpleButton } from '@mui/material/Button';
import { ButtonProps } from '../../helpers/types';

const CustomButton = styled(SimpleButton)<ButtonProps>(({disabled, variant, theme, size}) => ({
  textTransform: 'capitalize',
  boxShadow: 'none', //override material
  borderRadius: '3px',
  width: 'fit-content',
  height: 'fit-content',
  color: theme.palette.text.primary,
  fontSize: '14px',
  background: variant === 'contained' ? "#18CDCD" : "#FFFFFF",
  minWidth: size === 'small' ? '38px' : 'inherit',
  '&.MuiButton-root': {
    padding: size === 'small' ? '4px 8px' : '5px 13px',
  },
  '&:hover': {
    boxShadow: 'none', //override material
    background: disabled 
      ? '#D3D3D3' 
      : variant === 'contained'
      ? '#018184'  // primary dark color for contained
      : variant === 'outlined'
      ? '#18CDCD'  // primary for outlined
      : '#f5f5f5', // light gray for text variant
  },
}));

const Button = ({
  variant,
  loading,
  label,
  disabled,
  onClick,
  style,
  size,
  type,
  children,
  startIcon,
  endIcon,
  sx,
  color,
  ...props
}: ButtonProps) => {
  return (
    <CustomButton
      variant={variant}
      disabled={disabled}
      onClick={onClick}
      size={size}
      endIcon={endIcon}
      startIcon={startIcon}
      style={style}
      sx={sx}
      type={type}
      {...props}>
      {loading ? 'Loading...' : label}
      {children}
    </CustomButton>
  );
};

export default Button;
