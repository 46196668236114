import React from 'react';
import MuiAppBar, { AppBarProps as MuiAppBarProps } from '@mui/material/AppBar';
import { CardContent, styled } from '@mui/material';
import { Box } from 'mdi-material-ui';

import { DRAWER_WIDTH, theme } from '../../../styles/theme';

const drawerWidth = DRAWER_WIDTH;

interface AppBarProps extends MuiAppBarProps {
  open?: boolean;
}

export const AppBar = styled(MuiAppBar, {
  shouldForwardProp: prop => prop !== 'open',
})<AppBarProps>(({ theme, open }) => ({
  zIndex: theme.zIndex.drawer + 1,
  backgroundColor: theme.palette.background.paper,
  color: theme.palette.text.primary,
  boxShadow: 'none',
  borderBottom: `1px solid ${theme.palette.divider}`,
  transition: theme.transitions.create(['width', 'margin'], {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  width: open ? `calc(100% - ${drawerWidth}px)` : '92%',
  ...(open && {
    marginLeft: drawerWidth,
    transition: theme.transitions.create(['width', 'margin'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  }),
  [theme.breakpoints.down('sm')]: {
    width: open ? `calc(100% - ${drawerWidth}px)` : '90%',
  },
  [theme.breakpoints.up('md')]: {
    width: open ? `calc(100% - ${drawerWidth}px)` : '94%',
  },
  [theme.breakpoints.up('lg')]: {
    width: open ? `calc(100% - ${drawerWidth}px)` : '96%',
  },
  [theme.breakpoints.up('xl')]: {
    width: open ? `calc(100% - ${drawerWidth}px)` : '98%',
  },
}));

export const HeaderContainer = styled('div')(() => ({
  display: 'flex',
  justifyContent: 'space-between',
  width: '100%',
  alignItems: 'center',
}));

export const HeaderWrapper = {
  display: 'flex',
  alignItems: 'center',
  background: theme.palette.background.paper,
};

export const StatusContainer = styled('div')(({ theme }) => ({
  display: 'inline-flex',
  alignItems: 'center',
  marginLeft: '8px',
  fontSize: '14px',
  fontWeight: 'bold',
  fontFamily: theme.typography.fontFamily,
  border: `1px solid ${theme.palette.grey[300]}`,
  padding: '4px 8px',
  borderRadius: '16px',
  justifyContent: 'center',
}));

export const StatusDot = styled('div')(({ theme }) => ({
  width: '8px',
  height: '8px',
  borderRadius: '50%',
  backgroundColor: theme.palette.success.main,
  marginRight: '4px',
}));

export const StatusText = styled('span')(() => ({
  fontSize: '0.75rem',
  fontWeight: 'normal'
}));

export const CustomCardContent = styled(CardContent)({
  '&:last-child': {
    padding: 10,
  },
});
