import { MAX_NOTIFICATIONS, removeExpiredNotifications } from '../../utils';
import { ADD_NOTIFICATION } from '../constant';

const initialState = {
  data: [],
};

export const notificationReducer = (state = initialState, action: any) => {
  switch (action.type) {
    case ADD_NOTIFICATION: {
      const newNotification = action.payload;
      const updatedNotifications = [
        newNotification,
        ...state.data,
      ];
      const filteredNotifications =
        removeExpiredNotifications(updatedNotifications);
      const notificationsToKeep = filteredNotifications.slice(
        0,
        MAX_NOTIFICATIONS,
      );
      return {
        ...state,
        data: notificationsToKeep,
      };
    }
    default:
      return state;
  }
};
