import React, { useState } from 'react';
import { Box, Modal, Typography } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import CloseIcon from '@mui/icons-material/Close';
import { Button, GradientButton, Input } from '../../components';
import {
  ButtonWrapper,
  ManualTakedownId,
  ManualTakedownTitle,
  ManualTakedownWrapper,
} from './Style/TakedownList.style';

const ManualTakedown = ({ open, handleClose }: any) => {
  const [newTakedownId, setNewTakedownId] = useState<string>('');

  const navigate = useNavigate();

  const handleNewTakedownId = (e: any) => {
    setNewTakedownId(e.target.value);
  };

  const handleAddTakedown = () => {
    if (newTakedownId) {
      handleClose();
      setNewTakedownId('');
      navigate(`/enrich-takedown?id=${newTakedownId}`);
    }
  };

  return (
    <Modal open={open} onClose={handleClose}>
      <Box sx={ManualTakedownWrapper}>
        <ManualTakedownTitle>
          <Typography
            variant="h6"
            component="h2"
            sx={{ fontWeight: 600, color: 'black' }}>
            Add Manual Takedown
          </Typography>
          <CloseIcon onClick={handleClose} sx={{ cursor: 'pointer' }} />
        </ManualTakedownTitle>
        <hr style={{ margin: '10px' }} />
        <ManualTakedownId>
          <Typography sx={{ color: 'black', mt: 1.5 }}>
            Id <span style={{ color: 'red' }}>*</span>
          </Typography>
          <Input
            data-testid="manual-takedown-id-input"
            size="small"
            required
            onChange={handleNewTakedownId}
            placeholder="Takedown Id"
            value={newTakedownId}
          />
        </ManualTakedownId>
        <hr />
        <ButtonWrapper>
          <Button 
            data-testid="cancel-manual-takedown-button"
            variant="outlined" 
            onClick={handleClose} 
            color="secondary">
            Cancel
          </Button>
          <GradientButton
            data-testid="submit-manual-takedown-button"
            variant="contained"
            type="submit"
            onClick={handleAddTakedown}>
            Add
          </GradientButton>
        </ButtonWrapper>
      </Box>
    </Modal>
  );
};

export default ManualTakedown;
