import React, { useState, useCallback } from 'react';
import {
  Box,
  Typography,
  Modal,
  TextField,
  Autocomplete,
  Radio,
  RadioGroup,
  FormControlLabel,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  IconButton,
  TableContainer,
  Chip,
} from '@mui/material';
import { RemoveCircle, HighlightOff } from '@mui/icons-material';
import { useDispatch, useSelector } from 'react-redux';
import { useAuth0 } from '@auth0/auth0-react';
import { Button, GradientButton } from '../../components';
import { createThreatAction } from '../../store/threats/action';
import {
  AddThreatsMode,
  CreateThreatModelProps,
  DomainThreats,
  Errors,
  ThreatItem,
} from '../../helpers/types';
import {
  ButtonWrapper,
  ChipsWrapper,
  CloseButton,
  ModalWrapper,
} from './Style/Home.style';

const CreateThreatModel: React.FC<CreateThreatModelProps> = ({
  open,
  handleClose,
  userData,
}) => {
  const [mode, setMode] = useState<AddThreatsMode>('single');
  const [singleDomainThreats, setSingleDomainThreats] = useState<DomainThreats>(
    {
      protectedAssetId: '',
      protectedAssetName: '',
      companyId: '',
      threatName: '',
      createThreats: [],
    },
  );
  const [uniqueDomainThreats, setUniqueDomainThreats] = useState<
    DomainThreats[]
  >([
    {
      protectedAssetId: '',
      protectedAssetName: '',
      companyId: '',
      threatName: '',
      createThreats: [],
    },
  ]);
  const [errors, setErrors] = useState<Errors>({
    singleDomain: '',
    singleThreats: '',
    uniqueThreats: [],
  });

  const dispatch = useDispatch();
  const { user } = useAuth0();
  const protectedDomains = useSelector(
    (state: any) => state.threats?.domainList?.data?.Items,
  );

  const validateInputs = () => {
    let isValid = true;

    const urlRegex =
      /^(https?:\/\/)?([a-zA-Z0-9-]+\.)+[a-zA-Z]{2,6}(\/[^\s]*)?$/i;

    setErrors({
      singleDomain: '',
      singleThreats: '',
      uniqueThreats: [],
    });

    if (mode === 'single') {
      if (!singleDomainThreats.protectedAssetName) {
        setErrors((prev: Errors) => ({
          ...prev,
          singleDomain: 'Domain is required.',
        }));
        isValid = false;
      }

      if (singleDomainThreats.createThreats.length === 0) {
        setErrors((prev: Errors) => ({
          ...prev,
          singleThreats: 'At least one threat name is required.',
        }));
        isValid = false;
      } else if (
        singleDomainThreats.createThreats.some(
          (threat: string) => !urlRegex.test(threat),
        )
      ) {
        setErrors((prev: Errors) => ({
          ...prev,
          singleThreats: 'All threat names must be valid URLs.',
        }));
        isValid = false;
      }
    } else {
      const uniqueThreatsErrors = uniqueDomainThreats.map(
        (threat: DomainThreats) => {
          const errors = { domain: '', threats: '' };

          if (!threat.protectedAssetName) {
            errors.domain = 'Domain is required.';
            isValid = false;
          }

          if (threat.createThreats.length === 0) {
            errors.threats = 'At least one threat name is required.';
            isValid = false;
          } else if (
            threat.createThreats.some((name: string) => !urlRegex.test(name))
          ) {
            errors.threats = 'All threat names must be valid URLs.';
            isValid = false;
          }

          return errors;
        },
      );

      setErrors((prev: Errors) => ({
        ...prev,
        uniqueThreats: uniqueThreatsErrors,
      }));
    }

    return isValid;
  };

  const handleAddThreats = useCallback(() => {
    if (!validateInputs()) return;
    let createThreatRequest = [];
    if (mode === 'single') {
      createThreatRequest = singleDomainThreats?.createThreats?.map(
        (threat: string) => {
          return {
            threat_name: threat,
            status: 'confirmed',
            user_id: userData?.data?.Id || '',
            company_id: singleDomainThreats.companyId,
            process: 'w',
            priority: 1,
            protected_asset_id: singleDomainThreats.protectedAssetId,
          };
        },
      );
    } else {
      createThreatRequest =
        uniqueDomainThreats?.flatMap((domain: any) =>
          domain?.createThreats?.map((threat: string) => ({
            threat_name: threat,
            status: 'confirmed',
            user_id: userData?.data?.Id || '',
            company_id: domain.companyId,
            process: 'w',
            priority: 1,
            protected_asset_id: domain.protectedAssetId,
          })),
        ) || [];
    }

    dispatch(createThreatAction({ items: createThreatRequest }));
    resetForm();
  }, [singleDomainThreats, mode, uniqueDomainThreats]);

  const resetForm = () => {
    handleClose();

    setSingleDomainThreats({
      protectedAssetId: '',
      protectedAssetName: '',
      companyId: '',
      threatName: '',
      createThreats: [],
    });

    setUniqueDomainThreats([
      {
        protectedAssetId: '',
        protectedAssetName: '',
        companyId: '',
        threatName: '',
        createThreats: [],
      },
    ]);

    setErrors({
      singleDomain: '',
      singleThreats: '',
      uniqueThreats: [],
    });

    setMode('single');
  };

  const handleSingleThreatDomainChange = useCallback(
    (value: any) => {
      const protectedDomain = protectedDomains.find(
        (item: any) => item.Domain.Name === value,
      );
      if (protectedDomain) {
        setSingleDomainThreats((prev: any) => ({
          ...prev,
          protectedAssetName: value,
          protectedAssetId: protectedDomain?.Domain?.Id,
          companyId: protectedDomain?.Company?.Id,
        }));
      }
    },
    [protectedDomains],
  );

  const handleUniqueThreatDomainChange = useCallback(
    (value: any, index: number) => {
      const protectedDomain = protectedDomains.find(
        (item: any) => item.Domain.Name === value,
      );
      if (protectedDomain) {
        setUniqueDomainThreats((prevThreats: DomainThreats[]) =>
          prevThreats.map((threat: DomainThreats, i: number) =>
            i === index
              ? {
                  ...threat,
                  protectedAssetName: value,
                  protectedAssetId: protectedDomain?.Domain?.Id,
                  companyId: protectedDomain?.Company?.Id,
                }
              : threat,
          ),
        );
      }
    },
    [protectedDomains],
  );
  const handleAddRow = () => {
    setUniqueDomainThreats((prev: DomainThreats[]) => [
      ...prev,
      {
        protectedAssetId: '',
        protectedAssetName: '',
        companyId: '',
        threatName: '',
        createThreats: [],
      },
    ]);
  };

  const handleRemoveRow = (index: number) => {
    setUniqueDomainThreats((prevThreats: DomainThreats[]) =>
      prevThreats.filter((_, i) => i !== index),
    );

    setErrors((prevErrors: Errors) => ({
      ...prevErrors,
      uniqueThreats: prevErrors.uniqueThreats.filter(
        (_: any, i: number) => i !== index,
      ),
    }));
  };

  const handleCloseModal = () => {
    resetForm();
  };

  const handleSingleThreatsKeyDown = (e: React.KeyboardEvent) => {
    if (e.key === 'Enter' || e.key === ',' || e.key === ' ') {
      e.preventDefault();
      const trimmedName = singleDomainThreats.threatName.trim();

      const urlRegex =
        /^(https?:\/\/)?([a-zA-Z0-9-]+\.)+[a-zA-Z]{2,6}(\/[^\s]*)?$/i;

      if (trimmedName) {
        if (urlRegex.test(trimmedName)) {
          setSingleDomainThreats((prev: DomainThreats) => ({
            ...prev,
            createThreats: [...prev.createThreats, trimmedName],
            threatName: '',
          }));
          setErrors((prev: Errors) => ({
            ...prev,
            singleThreats: '',
          }));
        } else {
          setErrors((prev: Errors) => ({
            ...prev,
            singleThreats: 'Please enter a valid URL.',
          }));
        }
      }
    }
  };

  const handleUniqueThreatsKeyDown = (
    e: React.KeyboardEvent,
    index: number,
  ) => {
    if (e.key === 'Enter' || e.key === ',' || e.key === ' ') {
      e.preventDefault();
      const trimmedName = uniqueDomainThreats[index]?.threatName.trim();

      const urlRegex =
        /^(https?:\/\/)?([a-zA-Z0-9-]+\.)+[a-zA-Z]{2,6}(\/[^\s]*)?$/i;

      if (trimmedName) {
        if (urlRegex.test(trimmedName)) {
          setUniqueDomainThreats((prevThreats: DomainThreats[]) =>
            prevThreats.map((threat: any, i: number) =>
              i === index
                ? {
                    ...threat,
                    createThreats: [...threat.createThreats, trimmedName],
                    threatName: '',
                  }
                : threat,
            ),
          );
          setErrors((prev: Errors) => ({
            ...prev,
            uniqueThreats: prev.uniqueThreats.map((err: any, i: number) =>
              i === index ? { ...err, threats: '' } : err,
            ),
          }));
        } else {
          setErrors((prev: Errors) => ({
            ...prev,
            uniqueThreats: prev.uniqueThreats.map((err: any, i: number) =>
              i === index
                ? { ...err, threats: 'Please enter a valid URL.' }
                : err,
            ),
          }));
        }
      }
    }
  };

  const isAddThreatsDisabled = useCallback(() => {
    const isSingleMode = mode === 'single';

    const isThreatsInvalid = (threats: DomainThreats[]) =>
      threats.some(
        threat =>
          !threat.protectedAssetName || threat.createThreats.length === 0,
      );

    if (isSingleMode) {
      return isThreatsInvalid([singleDomainThreats]);
    }

    return isThreatsInvalid(uniqueDomainThreats);
  }, [mode, singleDomainThreats, uniqueDomainThreats]);

  const renderSingleMode = () => (
    <>
      <Typography sx={{ mt: 2, mb: 1 }}>Protected Domain :</Typography>
      <Autocomplete
        size="small"
        value={singleDomainThreats.protectedAssetName}
        onChange={(event, value) => handleSingleThreatDomainChange(value)}
        options={protectedDomains?.map((item: any) => item?.Domain?.Name) || []}
        renderInput={params => (
          <TextField
            {...params}
            placeholder="Select domain"
            error={!!errors.singleDomain}
            helperText={errors.singleDomain}
          />
        )}
      />
      <Typography sx={{ mt: 2, mb: 1 }}>Threat Names :</Typography>
      <TextField
        multiline
        rows={1}
        placeholder="Enter threat names, separated by commas or spaces"
        value={singleDomainThreats.threatName}
        onChange={e =>
          setSingleDomainThreats({
            ...singleDomainThreats,
            threatName: e.target.value,
          })
        }
        onKeyDown={handleSingleThreatsKeyDown}
        fullWidth
        error={!!errors.singleThreats}
        helperText={errors.singleThreats}
      />

      <ChipsWrapper>
        {singleDomainThreats?.createThreats?.map(
          (threat: string, index: number) => (
            <Chip
              key={index}
              label={threat}
              onDelete={() => {
                setSingleDomainThreats((prev: DomainThreats) => ({
                  ...prev,
                  createThreats: prev.createThreats.filter(
                    (_: any, i: number) => i !== index,
                  ),
                }));
              }}
            />
          ),
        )}
      </ChipsWrapper>
    </>
  );

  const renderUniqueMode = () => (
    <>
      <TableContainer sx={{ maxHeight: 300, overflowY: 'auto' }}>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>Domain</TableCell>
              <TableCell>Threat Name(s)</TableCell>
              <TableCell>Actions</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {uniqueDomainThreats.map((threat: DomainThreats, index: number) => (
              <TableRow key={index}>
                <TableCell sx={{ width: '40%' }}>
                  <Autocomplete
                    size="small"
                    value={threat.protectedAssetName}
                    onChange={(event, value) =>
                      handleUniqueThreatDomainChange(value, index)
                    }
                    options={
                      protectedDomains?.map(
                        (item: any) => item?.Domain?.Name,
                      ) || []
                    }
                    renderInput={params => (
                      <TextField
                        {...params}
                        placeholder="Select domain"
                        error={!!errors.uniqueThreats[index]?.domain}
                        helperText={errors.uniqueThreats[index]?.domain}
                      />
                    )}
                  />
                </TableCell>
                <TableCell sx={{ width: '50%' }}>
                  <TextField
                    multiline
                    rows={1}
                    placeholder="Enter threat names, separated by commas or spaces"
                    value={threat?.threatName}
                    onChange={e => {
                      setUniqueDomainThreats((prevThreats: DomainThreats[]) =>
                        prevThreats.map((threat: any, i: number) =>
                          i === index
                            ? {
                                ...threat,
                                threatName: e.target.value,
                              }
                            : threat,
                        ),
                      );
                    }}
                    onKeyDown={e => handleUniqueThreatsKeyDown(e, index)}
                    fullWidth
                    error={!!errors.uniqueThreats[index]?.threats}
                    helperText={errors.uniqueThreats[index]?.threats}
                  />
                  <ChipsWrapper sx={{ overflowX: 'auto', maxWidth: '100%' }}>
                    {threat?.createThreats?.map(
                      (chip: string, chipIndex: number) => (
                        <Chip
                          key={chipIndex}
                          label={chip}
                          onDelete={() => {
                            setUniqueDomainThreats(
                              (prevThreats: DomainThreats[]) =>
                                prevThreats.map(
                                  (threat: DomainThreats, i: number) =>
                                    i === index
                                      ? {
                                          ...threat,
                                          createThreats:
                                            threat.createThreats.filter(
                                              (threat: string) =>
                                                threat !== chip,
                                            ),
                                          threatName: '',
                                        }
                                      : threat,
                                ),
                            );
                          }}
                        />
                      ),
                    )}
                  </ChipsWrapper>
                </TableCell>

                <TableCell>
                  <IconButton onClick={() => handleRemoveRow(index)}>
                    <RemoveCircle />
                  </IconButton>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
      <Button
        onClick={handleAddRow}
        variant="outlined"
        color="primary"
        label="Add Row"
        sx={{ mt: 2 }}
      />
    </>
  );

  return (
    <Modal open={open} onClose={handleCloseModal}>
      <ModalWrapper data-testid="create-threat-modal">
        <CloseButton data-testid="close-threat-modal"
          onClick={handleCloseModal}>
          <HighlightOff />
        </CloseButton>
        <Typography variant="h6" component="h2">
          Add Threat Details
        </Typography>
        <RadioGroup
          row
          value={mode}
          onChange={e => setMode(e.target.value as AddThreatsMode)}
          sx={{ mt: 2, mb: 2 }}>
          <FormControlLabel
            value="single"
            control={<Radio />}
            label="Single Domain for All"
          />
          <FormControlLabel
            value="unique"
            control={<Radio />}
            label="Unique Domains for Each"
          />
        </RadioGroup>

        {mode === 'single' ? renderSingleMode() : renderUniqueMode()}

        <ButtonWrapper>
          <Button
            data-testid="cancel-threat-modal"
            onClick={handleCloseModal}
            variant="outlined"
            color="secondary"
            label="Cancel"
          />
          <GradientButton
            data-testid="submit-threat"
            onClick={handleAddThreats}
            label="Add Threats"
            type="submit"
            disabled={isAddThreatsDisabled()}
          />
        </ButtonWrapper>
      </ModalWrapper>
    </Modal>
  );
};

export default CreateThreatModel;
