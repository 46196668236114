import React from 'react';
import { Box } from '@mui/material';
import { StatusLabelProps } from '../../helpers/types';

const statusLabel = {
  padding: '4px 10px',
  borderRadius: '16px',
  fontSize: '12px',
  fontFamily: 'Poppins', //hardcoded for speed
  height: '18px',
  '&::first-letter': {                                                                                                
    textTransform: 'uppercase'                                                                                        
  } 
};

const StatusLabel = ({ label, bgcolor, style }: StatusLabelProps) => {
  return <Box sx={{ 
    border: `1px solid ${bgcolor}`, 
    color: bgcolor, 
    background: "#FFFFFF",
    ...statusLabel 
  }} style={style}>{label}</Box>;
};

export default StatusLabel;
