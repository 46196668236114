import * as React from 'react';
import Box from '@mui/material/Box';
import { Skeleton, Card } from '@mui/material';
import { CardWrapper } from '../ThreatCard/ThreatCard.style';


const ThreatCardSkeleton = () => {
  return (
    <Card sx={CardWrapper}>
      <Skeleton animation="wave" variant="text" sx={{ fontSize: '2rem' }} />
      <Box sx={{ pb: 1 }}>
        <Skeleton animation="wave" variant="rectangular" height={140} />
      </Box>
      <Box sx={{ display: 'flex', justifyContent: 'space-around', pb: 1}}>
        <Skeleton animation="wave" variant="rectangular" height={30} width={50} />
        <Skeleton animation="wave" variant="rectangular" height={30} width={50} />
        <Skeleton animation="wave" variant="rectangular" height={30} width={50} />
        <Skeleton animation="wave" variant="rectangular" height={30} width={50} />
      </Box>
      <Box sx={{ pb: 1 }}>
        <Skeleton animation="wave" variant="text" sx={{ fontSize: '0.7rem' }} width={'40%'} />
        <Skeleton animation="wave" variant="text" sx={{ fontSize: '0.7rem' }} width={'40%'} />
        <Skeleton animation="wave" variant="text" sx={{ fontSize: '0.7rem' }} width={'90%'} />
        <Skeleton animation="wave" variant="text" sx={{ fontSize: '0.7rem' }} width={'70%'} />
        <Skeleton animation="wave" variant="text" sx={{ fontSize: '0.7rem' }} width={'50%'} />
        <Skeleton animation="wave" variant="text" sx={{ fontSize: '0.7rem' }} width={'90%'} />
      </Box>
    </Card>
  );
}
export default ThreatCardSkeleton