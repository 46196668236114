import React, { useState } from 'react';
import {
  Typography,
  IconButton,
  Toolbar,
  Box,
  Menu,
  MenuItem,
  Tooltip,
  Drawer,
  List,
  Badge,
  Card,
} from '@mui/material';
import {
  AccountCircle,
  Notifications as NotificationsIcon,
} from '@mui/icons-material';
import { useAuth0 } from '@auth0/auth0-react';
import { useSelector } from 'react-redux';
import Wheel2Logo from '../../assets/Images/Wheel2Logo.png';
import {
  AppBar,
  HeaderContainer,
  HeaderWrapper,
  StatusContainer,
  StatusDot,
  StatusText,
  CustomCardContent,
} from './Style/Header.style';
import {
  HeaderProps,
  Notification,
  NotificationsState,
} from '../../helpers/types';
import { formatedDate } from '../../utils';

const Header = ({ open }: HeaderProps) => {
  const [anchorElUser, setAnchorElUser] = useState<null | HTMLElement>(null);
  const [drawerOpen, setDrawerOpen] = useState(false);
  const notifications = useSelector(
    (state: { notification: NotificationsState }) => state.notification.data,
  );
  const { logout, user } = useAuth0();

  const handleOpenUserMenu = (event: React.MouseEvent<HTMLElement>) =>
    setAnchorElUser(event.currentTarget);
  const handleCloseUserMenu = () => {
    localStorage.removeItem('userToken');
    setAnchorElUser(null);
    logout({ logoutParams: { returnTo: window.location.origin } });
  };

  const toggleDrawer = (open: boolean) => setDrawerOpen(open);

  const renderNotifications = () => (
    <List>
      {notifications.map((notification: Notification, index: number) => {
        const { thread_name, status, userId, date, errorMessage, isError } =
          notification;
        return (
          <Card
            variant="outlined"
            key={index}
            sx={{
              marginBottom: 1,
              marginTop: 1,
              padding: 0,
              borderColor: isError ? 'red' : '#ccc',
            }}>
            <CustomCardContent>
              <Typography variant="body2" color="text.primary">
                {isError ? (
                  <>
                    <strong>Error with {thread_name} operation:</strong>
                    {errorMessage}
                  </>
                ) : (
                  <>
                    <strong>{thread_name}</strong> changed status to{' '}
                    <strong>{status}</strong>
                  </>
                )}
              </Typography>
              <Box
                sx={{
                  display: 'flex',
                  justifyContent: 'space-between',
                  mt: 1,
                }}>
                <Typography variant="caption" color="text.secondary">
                  by {userId}
                </Typography>
                <Typography variant="caption" color="text.secondary">
                  {formatedDate(new Date(date))} UTC
                </Typography>
              </Box>
            </CustomCardContent>
          </Card>
        );
      })}
    </List>
  );

  return (
    <AppBar position="fixed" open={open}>
      <Toolbar>
        <HeaderContainer>
          <Box sx={{ display: 'flex', gap: 1 }}>
            <img src={Wheel2Logo} alt="wheel2" style={{ height: 30 }} />
          </Box>
          <Box sx={HeaderWrapper}>
            <Tooltip title="Server connection established. Live data is streaming and threats on the screen are updated in real time.">
              <StatusContainer aria-label="Live data streaming. Server connection established.">
                <StatusDot />
                <StatusText>Connected</StatusText>
              </StatusContainer>
            </Tooltip>
            <Typography sx={{ ml: 2, fontSize: '14px' }}>
              {user?.name}
            </Typography>
            <Box sx={{ flexGrow: 0, display: 'flex', alignItems: 'center' }}>
              <IconButton
                size="large"
                data-testid="account-button"
                edge="end"
                onClick={handleOpenUserMenu}
                color="inherit">
                <AccountCircle />
              </IconButton>
              <Menu
                sx={{ mt: '45px' }}
                anchorEl={anchorElUser}
                open={Boolean(anchorElUser)}
                onClose={() => setAnchorElUser(null)}
                anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
                transformOrigin={{ vertical: 'top', horizontal: 'right' }}
                keepMounted>
                <MenuItem>
                  <Typography textAlign="center">Profile</Typography>
                </MenuItem>
                <MenuItem onClick={handleCloseUserMenu}>
                  <Typography textAlign="center">Logout</Typography>
                </MenuItem>
              </Menu>
              <IconButton
                size="large"
                data-testid="notifications-button"
                onClick={() => toggleDrawer(true)}
                color="inherit">
                <Badge badgeContent={notifications.length} color="error">
                  <NotificationsIcon />
                </Badge>
              </IconButton>
            </Box>
          </Box>
        </HeaderContainer>
      </Toolbar>
      <Drawer
        anchor="right"
        open={drawerOpen}
        onClose={() => toggleDrawer(false)}
        variant="temporary"
        sx={{ zIndex: 1300 }}
        PaperProps={{
          sx: { boxShadow: 3 },
        }}>
        <Typography
          sx={{
            fontSize: 14,
            padding: '10px 15px',
            borderBottom: '1px solid #ccc',
          }}
          gutterBottom>
          Latest 50 threat status updates
        </Typography>
        <Box sx={{ width: 400, padding: '0 12px' }} role="presentation">
          {renderNotifications()}
        </Box>
      </Drawer>
    </AppBar>
  );
};

export default Header;
