import React from 'react';
import {
  Checkbox,
  CircularProgress,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from '@mui/material';
import { CustomTableCell } from '../CompaniesList/Style/CompaniesList.style';

const FalsePositiveTable = ({
  data: falsePositive,
  loading,
}: {
  data: string[];
  loading: boolean;
}) => {
  return (
      <TableContainer component={Paper} sx={{ border: '1px solid lightgray' }}>
        <Table size="small">
          <TableHead>
            <TableRow>
              <CustomTableCell align="left">Domains Name</CustomTableCell>
              <CustomTableCell align="left">Created By</CustomTableCell>
              <CustomTableCell align="left">Created Date</CustomTableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {loading ? (
              <TableRow>
                <TableCell colSpan={4} align="center">
                  <CircularProgress size={24} />
                </TableCell>
              </TableRow>
            ) : falsePositive.length > 0 ? (
              falsePositive.map((item: any, index) => {
                return (
                  <TableRow key={index}>
                    <TableCell>{item}</TableCell>
                    <TableCell align="left"></TableCell>
                    <TableCell align="left"></TableCell>
                  </TableRow>
                );
              })
            ) : (
              <TableRow>
                <TableCell colSpan={4} align="center">
                  <Typography variant="body2" sx={{ color: 'gray' }}>
                    No data available
                  </Typography>
                </TableCell>
              </TableRow>
            )}
          </TableBody>
        </Table>
      </TableContainer>
  );
};

export default FalsePositiveTable;
