import React, { useState } from 'react';
import {
  Box,
  Checkbox,
  Menu,
  MenuItem,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Typography,
} from '@mui/material';
import { useSelector, useDispatch } from 'react-redux';
import { useParams } from 'react-router-dom';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import {
  Button,
  ConfirmationModal,
  Input,
  SelectOption,
} from '../../../components';
import { CustomTableCell } from '../../CompaniesList/Style/CompaniesList.style';
import {
  LoaderWrapper,
  NoDataMessage,
  companyStatusList,
  formatedDate,
} from '../../../utils';
import AddProtectedDomainModal from './AddProtectedDomainModal';
import {
  addInformationGenericAction,
  changeStatusDomainAction,
  domainUnprotectAction,
} from '../../../store/company/action';
import {
  ActionsButton,
  DomainNameStyle,
  FilterWrapper,
  ProgressBar,
  ProgressCount,
  ProgressText,
  ProgressWrapper,
} from './Style/Tabs.style';

const ProtectedDomainsTab = ({
  handleChangeDomainName,
  searchDomainName,
  domainCurrentPage,
  domainCount,
  handleChangeDomainPage,
  onDomainClick,
}: any) => {
  const [openActionMenu, setOpenActionMenu] = useState<null>(null);
  const [openAddDomain, setOpenAddDomain] = useState<boolean>(false);
  const [selectedDomain, setSelectedDomain] = useState<any>(null);
  const [openConfirmModal, setOpenConfirmModal] = useState<boolean>(false);
  const [selectedBulkDomain, setSelectedBulkDomain] = useState<any>([]);

  const open = Boolean(openActionMenu);
  const { id } = useParams();
  const dispatch = useDispatch();

  const { getCompanyDetail, domainProgress } = useSelector(
    (state: any) => state.company,
  );
  const domainsList = getCompanyDetail.data?.Domains;

  const handleOpenAddDomain = () => {
    setOpenAddDomain(true);
  };

  const handleCloseAddDomain = () => {
    setOpenAddDomain(false);
  };

  const handleDomainActions = (event: any, item: any) => {
    setOpenActionMenu(event.currentTarget);
    setSelectedDomain(item);
  };

  const handleMenuAction = () => {
    setOpenActionMenu(null);
    setSelectedDomain(null);
  };

  const handleUnprotect = () => {
    dispatch(
      domainUnprotectAction({
        domainId: selectedDomain.Id,
        companyId: id,
        pn: domainCurrentPage,
        h: searchDomainName,
      }),
    );
    setOpenActionMenu(null);
  };

  const handleChangeDomainStatus = (domain: string, status: string) => {
    dispatch(
      changeStatusDomainAction({
        companyId: id,
        domain: domain,
        status: status,
      }),
    );
  };

  const updateBulkDomainStatus = () => {
    selectedBulkDomain.forEach((item: any) => {
      dispatch(
        domainUnprotectAction({
          domainId: item.Id,
          companyId: id,
          pn: domainCurrentPage,
          h: searchDomainName,
        }),
      );
    });
    setOpenConfirmModal(false);
    setSelectedBulkDomain([]);
  };

  const handleSelectAllDomain = (event: any) => {
    if (event.target.checked) {
      const newSelecteds = domainsList.map((row: any) => row);
      setSelectedBulkDomain(newSelecteds);
      return;
    }
    setSelectedBulkDomain([]);
  };

  const handleClickDomain = (event: any, item: any) => {
    const selectedIndex = selectedBulkDomain.indexOf(item);
    let newSelected: any = [];

    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selectedBulkDomain, item);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selectedBulkDomain.slice(1));
    } else if (selectedIndex === selectedBulkDomain?.length - 1) {
      newSelected = newSelected.concat(selectedBulkDomain.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
        selectedBulkDomain.slice(0, selectedIndex),
        selectedBulkDomain.slice(selectedIndex + 1),
      );
    }
    setSelectedBulkDomain(newSelected);
  };

  const isSelected: any = (item: any) =>
    selectedBulkDomain.indexOf(item) !== -1;

  const handleGeneric = (domain: string, currentValue: string) => {
    const newValue = currentValue === 'y' ? 'n' : 'y';
    dispatch(
      addInformationGenericAction({
        domain: domain,
        key: 'generic',
        value: newValue,
        c: id,
      }),
    );
  };

  const getGenericValue = (informations: any) => {
    if (!informations || !Array.isArray(informations)) {
      return 'n';
    }
    const genericInfo = informations
      .filter((info: any) => info.Key === 'generic')
      .sort(
        (a: any, b: any) =>
          new Date(b.Created).getTime() - new Date(a.Created).getTime(),
      )[0];
    return genericInfo ? genericInfo.Value : 'n';
  };

  return (
    <div>
      <FilterWrapper>
        {selectedBulkDomain?.length > 0 ? (
          <Box sx={{ display: 'flex', gap: 2, alignItems: 'center' }}>
            <Typography variant="body1" sx={{ marginLeft: 2 }}>
              {selectedBulkDomain?.length} selected
            </Typography>
            <Button
              variant="contained"
              sx={{ background: '#f1416c' }}
              onClick={() => setOpenConfirmModal(true)}>
              Delete Selected
            </Button>
          </Box>
        ) : (
          <ProgressWrapper>
            {domainProgress?.total > 0 && (
              <>
                <ProgressText variant="body2">
                  Adding bulk domain process has been started
                </ProgressText>

                <ProgressBar
                  variant="determinate"
                  value={
                    (domainProgress.processed / domainProgress.total) * 100
                  }
                  aria-label="Domain addition progress"
                  aria-valuenow={domainProgress.processed}
                  aria-valuemin={0}
                  aria-valuemax={domainProgress.total}
                />

                <ProgressCount variant="body2">
                  {domainProgress.processed} / {domainProgress.total}
                </ProgressCount>
              </>
            )}
          </ProgressWrapper>
        )}
        <Button
          data-testid="add-protected-domain-button"
          variant="contained"
          sx={{ background: '#0fd6c2' }}
          onClick={handleOpenAddDomain}>
          + Add Domain
        </Button>
      </FilterWrapper>
      <Table size="small">
        <TableHead>
          <TableRow>
            <CustomTableCell>
              <Checkbox
                size="small"
                checked={
                  domainsList?.length > 0 &&
                  selectedBulkDomain?.length === domainsList?.length
                }
                onChange={handleSelectAllDomain}
              />
            </CustomTableCell>
            <CustomTableCell align="left">DOMAIN NAME</CustomTableCell>
            <CustomTableCell align="left">IP ADDRESS</CustomTableCell>
            <CustomTableCell align="left">STATUS</CustomTableCell>
            <CustomTableCell align="left">GENERIC</CustomTableCell>
            <CustomTableCell align="left">LAST SEEN</CustomTableCell>
            <CustomTableCell align="left">ACTIONS</CustomTableCell>
          </TableRow>
        </TableHead>
        {getCompanyDetail?.loading ? (
          <LoaderWrapper size={25} />
        ) : domainsList?.length === 0 ? (
          <NoDataMessage message="No Domain Available" />
        ) : (
          <TableBody>
            {domainsList?.map((item: any) => {
              const isItemSelected = isSelected(item);
              const genericValue = getGenericValue(item?.Informations);
              return (
                <TableRow key={item?.Name} tabIndex={-1}>
                  <TableCell>
                    <Checkbox
                      size="small"
                      checked={isItemSelected}
                      onClick={event => handleClickDomain(event, item)}
                    />
                  </TableCell>
                  <TableCell onClick={() => onDomainClick(item)}>
                    <DomainNameStyle>{item.Name}</DomainNameStyle>
                  </TableCell>
                  <TableCell align="left">{item.ipAddress || '-'}</TableCell>
                  <TableCell align="left">
                    <SelectOption
                      size="small"
                      width="190px"
                      value={item?.Status}
                      options={companyStatusList}
                      onChange={(e: any) =>
                        handleChangeDomainStatus(item.Name, e.target.value)
                      }
                    />
                  </TableCell>
                  <TableCell align="left">
                    <Checkbox
                      size="small"
                      checked={genericValue === 'y'}
                      onClick={() => handleGeneric(item?.Name, genericValue)}
                    />
                  </TableCell>
                  <TableCell align="left">
                    {formatedDate(item?.Created)}
                  </TableCell>
                  <TableCell align="left">
                    <ActionsButton
                      size="small"
                      onClick={event => handleDomainActions(event, item)}
                      variant="contained"
                      endIcon={<KeyboardArrowDownIcon />}>
                      Actions
                    </ActionsButton>
                  </TableCell>
                </TableRow>
              );
            })}
          </TableBody>
        )}
      </Table>
      <AddProtectedDomainModal
        handleClose={handleCloseAddDomain}
        open={openAddDomain}
      />
      <Menu anchorEl={openActionMenu} open={open} onClose={handleMenuAction}>
        <MenuItem onClick={() => onDomainClick(selectedDomain)}>View</MenuItem>
        <MenuItem onClick={handleUnprotect}>Unprotect</MenuItem>
      </Menu>
      <ConfirmationModal
        title="Are you sure you want to unprotect selected domains ?"
        subTitle="Do you want to change the status?"
        confirmLabel="Yes, Unprotect!"
        open={openConfirmModal}
        handleClose={() => setOpenConfirmModal(false)}
        handleUpdate={updateBulkDomainStatus}
      />
    </div>
  );
};

export default ProtectedDomainsTab;
