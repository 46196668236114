import React from 'react';
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from '@mui/material';
import { TableHeaderCell, TakedownTable } from '../Style/SingleThreat.style';
import { formatedDate, SSLCertificateHeader } from '../../../utils';

const SSLCertificateTab = ({ sslCertificate }: any) => {
  const sslData = sslCertificate?.data;
  return (
      <TakedownTable>
        <TableContainer data-testid="ssl-certificate-table" sx={{ maxHeight: 400 }}>
          <Table stickyHeader size="small" sx={{ border: 0 }}>
            <TableHead>
              <TableRow>
                {SSLCertificateHeader.map((item, index) => (
                  <TableHeaderCell key={index} align="left">
                    {item}
                  </TableHeaderCell>
                ))}
              </TableRow>
            </TableHead>
            <TableBody>
              <TableRow>
                <TableCell>{sslCertificate?.asset_name || '-'}</TableCell>
                <TableCell>{sslData?.issuer || '-'}</TableCell>
                <TableCell>
                  {sslData && sslData?.not_before !== null
                    ? formatedDate(sslData?.not_before)
                    : '-'}
                </TableCell>
                <TableCell>
                  {sslData && sslData?.not_after !== null
                    ? formatedDate(sslData?.not_after)
                    : '-'}
                </TableCell>
              </TableRow>
            </TableBody>
          </Table>
        </TableContainer>
      </TakedownTable>
  );
};

export default SSLCertificateTab;
