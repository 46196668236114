import { styled } from '@mui/material/styles';
import { Autocomplete } from '@mui/material';
import { OptionType } from '../SearchableDropDown';


export const StyledAutocomplete = styled(Autocomplete<OptionType, boolean, false, false>)(({ theme }) => ({
  '& .MuiOutlinedInput-root': {
    backgroundColor: theme.palette.background.paper,
    textTransform: 'capitalize',
    borderRadius: '8px',
    '& fieldset': {
      borderColor: theme.palette.divider,
    },
    '&:hover fieldset': {
      borderColor: theme.palette.primary.main,
    },
    '&.Mui-focused fieldset': {
      borderColor: theme.palette.primary.main,
    }
  },
  '& .MuiInputBase-input': {

  },
  '& .MuiAutocomplete-tag': { 
    //failing to be adding this to a var because of typescript, not going to spend time on this 
    //backgroundColor: theme.palette.background.tertiary,
    backgroundColor: '#F2F4F7',
    color: theme.palette.text.primary,
    fontWeight: '400',
    borderRadius: '6px',
    '& .MuiChip-deleteIcon': {
      width: '14px',
      color: theme.palette.text.primary,
      '&:hover': {
        color: theme.palette.error.main,
      }
    }
  },
  '& .MuiAutocomplete-option': {
    '&[aria-selected="true"]': {
      backgroundColor: `${theme.palette.primary.main}14`,
    },
    '&.Mui-focused': {
      backgroundColor: `${theme.palette.primary.main}14`,
    }
  },
  
}));
