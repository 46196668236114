import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Box, Typography } from '@mui/material';
import { Button } from '../../components';
import { FilterWrapper } from '../CompaniesList/Style/CompaniesList.style';
import FalsePositiveTable from './FalsePositiveTable';
import { getFalsePositiveAction } from '../../store/falsePositive/action';
import { createFalsePositiveeDomainsApi } from '../../store/falsePositive';
import { showToast } from '../../store/toaster/action';
import { calculateLastWeekDate, convertToUnixTimestamp } from '../../utils';
import LoadDomainModal from '../../components/LoadDomainsModal/LoadDomainModal';

const FalsePositive = () => {
  const [openFalseLoadDomain, setOpenFalseLoadDomain] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  const dispatch = useDispatch();
  const { data, loading, error } = useSelector(
    (state: any) => state.falsePositive.getFalsePositive,
  );

  const handleOpenFalseLoadDomain = () => {
    setOpenFalseLoadDomain(true);
  };

  const handleCloseFalsePositiveDomain = () => {
    setOpenFalseLoadDomain(false);
  };

  const handleSubmitFalsePositiveDomains = async (domains: string) => {
    const FalsedomainsArray = domains
      .split(',')
      .map(domain => domain.trim())
      .filter(Boolean);

    setIsLoading(true);

    try {
      await createFalsePositiveeDomainsApi(FalsedomainsArray);
      dispatch(showToast('Domains added successfully', 'success'));
      handleCloseFalsePositiveDomain();
    } catch (err) {
      dispatch(showToast('Failed to add domains', 'error'));
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    const selectedStartDate = calculateLastWeekDate();
    const unixTimestamp = convertToUnixTimestamp(selectedStartDate);
    dispatch(getFalsePositiveAction({ startDate: unixTimestamp }));
  }, [dispatch]);

  return (
    <>
      <Box data-testid="false-positive-container" sx={{ padding: '24px' }}>
        <Typography 
          data-testid="false-positive-title" 
          variant="h6" 
          sx={{ fontWeight: 600, color: 'black' }}>
          False Positive
        </Typography>
        <FilterWrapper data-testid="false-positive-filter-section">
          <Box sx={{ display: 'flex', gap: '12px' }}>
            <Typography variant="body1" sx={{ fontWeight: 500 }}>
              Last 7 days list
            </Typography>
          </Box>
          <Box>
            <Button
              data-testid="load-false-positive-domain-button"
              variant="contained"
              sx={{ color: '#FFFFFF' }}
              onClick={handleOpenFalseLoadDomain}>
              + Load Domain
            </Button>
          </Box>
        </FilterWrapper>
        <FalsePositiveTable 
          data-testid="false-positive-table"
          data={data} 
          loading={loading} 
        />
      </Box>
      <LoadDomainModal
        data-testid="false-positive-load-modal"
        title="Load False Positive"
        handleClose={handleCloseFalsePositiveDomain}
        handleSubmit={handleSubmitFalsePositiveDomains}
        open={openFalseLoadDomain}
        isLoading={isLoading}
      />
    </>
  );
};

export default FalsePositive;
