/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from 'react';
import { Avatar, Typography, Box, Grid, IconButton } from '@mui/material';
import { useParams, useSearchParams } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import OfflinePinIcon from '@mui/icons-material/OfflinePin';
import PlaylistAddCircleIcon from '@mui/icons-material/PlaylistAddCircle';
import CancelIcon from '@mui/icons-material/Cancel';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import {
  Button,
  ConfirmationModal,
  ImageSkeleton,
  Loader,
} from '../../components';
import { ThreatTypes } from '../../helpers/types';
import {
  SingleThreatLoaderWrapper,
  StatusButtonWrapper,
  ImageStyle,
  ScreenshotWrapper,
  SyncIconStyle,
  MainContainer,
  ThreatNameStyled,
  ThreatCard,
} from './Style/SingleThreat.style';
import Avtar from '../../assets/Images/Avtar.png';
import {
  changeStatusLabelAction,
  getThreatActionDetail,
} from '../../store/threats/action';
import { getThreatScreenshot } from '../../store/threats';
import ScreenShotModal from '../../components/ScreenShotModal/ScreenShotModal';
import StatusHistory from './StatusHistory';
import SummaryDetail from './SummaryDetail';
import DomainInformation from './DomainInformation';
import ExternalToolList from './ExternalToolList';
import TakedownDetails from './TakedownDetails';
import NotesComments from './NotesComments';
import { PAGE_NAME } from '../../utils/analytic';
import { setAnalyticObject } from '../../store/analytic/action';
import { getNotesValuesAction } from '../../store/noteComments/action';
import GSBStatusDetails from '../GSBHistory/GSBStatus';
import RefreshButton from '../../components/RefreshButton/RefreshButton';

const SingleThreatDetail = () => {
  const [singleThreat, setSingleThreat] = useState<ThreatTypes | null>(null);
  const [imageData, setImageData] = useState<string | null>(null);
  const [statusLabelThreat, setStatusLabelThreat] = useState('');
  const [openModal, setOpenModal] = useState<boolean>(false);
  const [openScreenShotModal, setOpenScreenShotModal] =
    useState<boolean>(false);
  const [imageLoader, setImageLoader] = useState<boolean>(false);
  const [singleThreatStatus, setSingleThreatStatus] = useState<boolean>(true);
  const [startDateTime, setStartDateTime] = useState<any>(null);
  const [copied, setCopied] = useState<boolean>(false);
  const [companyDetail, setCompanyDetail] = useState<any>();

  const user = JSON.parse(window.localStorage.getItem('authUserSub') || '{}');

  const { userData } = useSelector((state: any) => state.user);

  const dispatch = useDispatch();
  const { data: threatDetail, loading } = useSelector(
    (state: any) => state.threats.threatDetail,
  );
  const { getCompany } = useSelector((state: any) => state.company);

  const [searchParams] = useSearchParams();
  const { threatName, domainName, companyId } = useParams();
  const protectedAssetId = searchParams.get('protected_id');
  const threatId = searchParams.get('threat_id');

  useEffect(() => {
    if (threatName && domainName && companyId && protectedAssetId && threatId) {
      dispatch(
        getThreatActionDetail({
          protected_id: protectedAssetId,
          threat_id: threatId,
        }),
      );
    }
  }, [threatName, domainName, companyId, protectedAssetId, threatId, dispatch]);

  useEffect(() => {
    setSingleThreat(threatDetail);
    const companies = getCompany?.data?.find(
      (c: any) => c?.Id === threatDetail?.company_id,
    );
    setCompanyDetail(companies);

    // Update page title
    if (threatDetail?.threat_name) {
      document.title = `${threatDetail.threat_name} - Threat Details`;
    }

    // Cleanup - restore original title when component unmounts
    return () => {
      document.title = 'Single threat';
    };
  }, [threatDetail]);

  useEffect(() => {
    setStartDateTime(Date.now());
  }, []);

  const fetchThreatScreenshot = async (threatName: string | undefined) => {
    try {
      setImageLoader(true);
      const res = await getThreatScreenshot(threatName);
      setImageData(res.data.Image);
    } catch (error) {
      console.error('Error fetching threat screenshot', error);
      setImageData(null);
    } finally {
      setImageLoader(false);
    }
  };

  useEffect(() => {
    if (singleThreat?.threat_name) {
      dispatch(getNotesValuesAction());
    }
  }, [singleThreat?.threat_name]);

  useEffect(() => {
    if (singleThreat?.threat_name) {
      fetchThreatScreenshot(singleThreat?.threat_name);
    }
  }, [singleThreat?.threat_name]);

  const handleSyncIconClick = async () => {
    if (singleThreat?.threat_name) {
      fetchThreatScreenshot(singleThreat?.threat_name);
    }
  };

  const handleOpenSingleModal = (value: string) => {
    setStatusLabelThreat(value);
    setOpenModal(true);
  };

  const timeSpentOnPage = () => {
    const endTime = Date.now();
    const timeDiff = endTime - startDateTime;
    const timeSpentInSeconds = timeDiff / 1000;

    const analyticData = {
      domain: {
        id: singleThreat?.protected_asset_id,
        name: singleThreat?.protected_asset_name,
      },
      threat: {
        id: singleThreat?.threat_id,
        name: singleThreat?.threat_name,
      },
      user: { email: user?.email, name: user?.name },
      currentStatus: singleThreat?.identification_status.value,
      updatedStatus: statusLabelThreat,
      pageName: PAGE_NAME.SINGLE_THREAT_PAGE,
    };
  };

  const updateStatusDetailScreen = () => {
    if (singleThreat) {
      setOpenModal(false);
      const analytic = {
        pageName: PAGE_NAME.SINGLE_THREAT_PAGE,
        user: { email: user?.email, name: user?.name },
        isBulkUpdate: false,
      };
      dispatch(setAnalyticObject(analytic));
      timeSpentOnPage();
      dispatch(
        changeStatusLabelAction({
          threat_name: singleThreat?.threat_name,
          status: statusLabelThreat,
          user_id: userData?.data?.Id,
          company_id: companyId,
          threat_id: singleThreat?.threat_id,
          protected_asset_id: singleThreat?.protected_asset_id,
          protected_asset_name: singleThreat?.protected_asset_name,
          process: 'w',
          priority: 1,
          singleThreatStatus,
          currentStatus: singleThreat?.identification_status?.value,
        }),
      );
    }
  };

  const handleCopyThreatName = () => {
    navigator.clipboard.writeText(singleThreat?.threat_name || '');
    setCopied(true);
    setTimeout(() => setCopied(false), 2000);
  };

  const handleCloseScreenShot = () => {
    setOpenScreenShotModal(false);
  };

  if (loading) {
    return (
      <SingleThreatLoaderWrapper>
        <Loader size={30} />
      </SingleThreatLoaderWrapper>
    );
  }

  const detectionDate = new Date(threatDetail?.detection_date || '');
  const options: any = {
    year: 'numeric',
    month: 'long',
    day: 'numeric',
    hour: 'numeric',
    minute: 'numeric',
    hour12: true,
  };
  const formattedDate = detectionDate.toLocaleString('en-US', options);

  return (
    <MainContainer container spacing={2}>
      <Grid item xs={6}>
        {/* Top header */}
        <ThreatNameStyled>
          <Box sx={{ display: 'flex', gap: '18px' }}>
            <Typography variant="h5" fontWeight="bold">
              {threatDetail?.threat_name}
            </Typography>
            <IconButton
              data-testid="copy-threat-name-button"
              onClick={handleCopyThreatName}
              size="small"
              sx={{ padding: '0' }}
              disabled={copied}>
              <ContentCopyIcon />
            </IconButton>
          </Box>

          <Typography variant="h6" sx={{ lineHeight: 1.1 }}>
            {threatDetail?.protected_asset_name} - {companyDetail?.Name}
          </Typography>

          <Typography variant="subtitle1" sx={{ lineHeight: 2 }}>
            <b>Detection:</b> {`On ${formattedDate}`} <br />
          </Typography>
          <StatusButtonWrapper>
            <Button
              data-testid="confirm-threat-button"
              variant="contained"
              onClick={() => {
                handleOpenSingleModal('confirmed');
              }}
              startIcon={<CheckCircleIcon />}
              disabled={
                singleThreat?.identification_status?.value === 'confirmed'
                  ? true
                  : false
              }>
              Confirm
            </Button>
            <Button
              data-testid="discard-threat-button"
              variant="contained"
              onClick={() => {
                handleOpenSingleModal('discarded');
              }}
              startIcon={<CancelIcon />}
              disabled={
                singleThreat?.identification_status?.value === 'discarded'
                  ? true
                  : false
              }>
              Discard
            </Button>
            <Button
              variant="contained"
              onClick={() => {
                handleOpenSingleModal('trademark');
              }}
              startIcon={<OfflinePinIcon />}
              disabled={
                singleThreat?.identification_status?.value === 'trademark'
                  ? true
                  : false
              }>
              TradeMark
            </Button>
            <Button
              variant="contained"
              onClick={() => {
                handleOpenSingleModal('owned');
              }}
              startIcon={<PlaylistAddCircleIcon />}
              disabled={
                singleThreat?.identification_status?.value === 'owned'
                  ? true
                  : false
              }>
              Owned
            </Button>
          </StatusButtonWrapper>
        </ThreatNameStyled>
        {/* Summary */}
        <ThreatCard>
          <SummaryDetail
            singleThreat={singleThreat}
            threatDetail={threatDetail}
          />
        </ThreatCard>
        {/* GSB */}
        <ThreatCard>
          <GSBStatusDetails threatDetail={threatDetail} />
        </ThreatCard>
         {/* Takedowns */}
        {singleThreat?.identification_status?.value === 'confirmed' && (
          <ThreatCard>
            <TakedownDetails singleThreat={singleThreat} />
          </ThreatCard>
        )}
        {/* Domain info */}
        <ThreatCard>
          <DomainInformation singleThreat={singleThreat} />
        </ThreatCard>
      </Grid>
      
      <Grid item xs={6}>
        <ExternalToolList
          singleThreat={singleThreat}
          imageData={imageData}
          threatName={threatDetail?.threat_name}
        />
        <ScreenshotWrapper>
          <Box
            sx={{
              display: 'flex',
              alignItems: 'center',
              paddingBottom: '8px',
            }}>
            <Typography variant="h6" sx={{ color: 'black' }}>
              Screenshot
            </Typography>
            <RefreshButton
              handleSyncIconClick={handleSyncIconClick}
              isDisabled={imageLoader}
              SyncIconStyle={SyncIconStyle}
            />
          </Box>
          {imageLoader ? (
            <ImageSkeleton height={300} />
          ) : (
            <Avatar
              variant={'square'}
              src={
                imageData === undefined
                  ? Avtar
                  : `data:image/jpeg;base64,${imageData}`
              }
              onClick={() => setOpenScreenShotModal(true)}
              sx={ImageStyle}
            />
          )}
        </ScreenshotWrapper>
        <StatusHistory singleThreat={singleThreat} />
        <NotesComments singleThreat={singleThreat} />
      </Grid>
      <ScreenShotModal
        imageData={imageData}
        handleCloseScreenShot={handleCloseScreenShot}
        openScreenShotModal={openScreenShotModal}
      />
      <ConfirmationModal
        title="Confirm status"
        subTitle="Do you want to change the status?"
        confirmLabel="Update"
        open={openModal}
        handleClose={() => setOpenModal(false)}
        handleUpdate={() => updateStatusDetailScreen()}
      />
    </MainContainer>
  );
};

export default SingleThreatDetail;
