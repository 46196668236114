import React, { useEffect, useState, useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Box, Typography } from '@mui/material';
import { Button } from '../../components';
import { FilterWrapper } from '../CompaniesList/Style/CompaniesList.style';
import ActiveDefenseTable from './ActiveDefenseTable';
import { getActiveDefenceAction } from '../../store/activeDefence/action';
import { calculateLastWeekDate, convertToUnixTimestamp } from '../../utils';
import { createActiveDefenceDomainsApi } from '../../store/activeDefence';
import { showToast } from '../../store/toaster/action';
import LoadDomainModal from '../../components/LoadDomainsModal/LoadDomainModal';

const ActiveDefense = () => {
  const [openActiveDefenseModal, setOpenActiveDefenseModal] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  const dispatch = useDispatch();
  const { data, loading, error } = useSelector(
    (state: any) => state.activeDefence.activeDefence,
  );

  const handleOpenActiveLoadDomain = () => setOpenActiveDefenseModal(true);
  const handleCloseActiveDefenseModal = () => setOpenActiveDefenseModal(false);

  const handleSubmitActiveDefenseDomains = useCallback(
    async (domains: string) => {
      const ActiveDomainsArray = domains
        .split(',')
        .map(domain => domain.trim())
        .filter(Boolean);

      setIsLoading(true);
      try {
        await createActiveDefenceDomainsApi(ActiveDomainsArray);
        dispatch(showToast('Domains added successfully', 'success'));
        handleCloseActiveDefenseModal();
      } catch (err) {
        dispatch(showToast('Failed to add domains', 'error'));
      } finally {
        setIsLoading(false);
      }
    },
    [dispatch],
  );

  useEffect(() => {
    const selectedStartDate = calculateLastWeekDate();
    const unixTimestamp = convertToUnixTimestamp(selectedStartDate);
    dispatch(getActiveDefenceAction({ startDate: unixTimestamp }));
  }, [dispatch]);

  return (
    <>
      <Box sx={{ padding: '24px' }}>
        <Typography variant="h6" sx={{ fontWeight: 600, color: 'black' }}>
          Network Disruption
        </Typography>
        <FilterWrapper>
          <Box sx={{ display: 'flex', gap: '12px' }}>
            <Typography variant="body1" sx={{ fontWeight: 500 }}>
              Last 7 days list
            </Typography>
          </Box>
          <Box>
            <Button
              data-testid="load-domain-button"
              variant="contained"
              sx={{color: '#FFFFFF' }}
              onClick={handleOpenActiveLoadDomain}>
              + Load Domain
            </Button>
          </Box>
        </FilterWrapper>
        <ActiveDefenseTable data={data} loading={loading} />
      </Box>
      <LoadDomainModal
        title="Load Network Disruption"
        handleClose={handleCloseActiveDefenseModal}
        handleSubmit={handleSubmitActiveDefenseDomains}
        open={openActiveDefenseModal}
        isLoading={isLoading}
      />
    </>
  );
};

export default ActiveDefense;
