import React, { useEffect } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import {
  Box,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from '@mui/material';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import { useDispatch, useSelector } from 'react-redux';
import { takedownStatusHistrory } from '../../store/takedown/action';
import { LoaderWrapper } from '../../utils';
import { Button } from '../../components';

const TakedownStatusHistory = () => {
  const { takedown_id } = useParams<{ takedown_id: string }>();
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const { takedownStatusHistory, getStatustakeDown } = useSelector(
    (state: any) => state.takedown,
  );
  const statusHistory = takedownStatusHistory?.data;

  useEffect(() => {
    if (takedown_id) {
      dispatch(takedownStatusHistrory({ takedown_id: takedown_id }));
    }
  }, [dispatch, takedown_id]);

  const findStatusNameById = (id: number) => {
    const status = getStatustakeDown?.data?.find(
      (status: { id: number }) => status?.id === id,
    );
    return status ? status.name : 'Unknown';
  };

  return (
    <Box sx={{ padding: '24px' }}>
      <Typography variant="h6" sx={{ fontWeight: 600, color: 'black' }}>
        Takedowns Status History
      </Typography>
      <Button
        data-testid="back-to-takedowns-button"
        onClick={() => navigate(-1)}
        variant="outlined"
        size="small"
        startIcon={<ArrowBackIcon />}
        sx={{ margin: '16px 0' }}>
        Back
      </Button>
      <TableContainer
        data-testid="takedown-history-table"
        component={Paper}
        sx={{ border: '1px solid lightgray', mt: 2 }}>
        <Table size="small">
          <TableHead>
            <TableRow>
              <TableCell>Id</TableCell>
              <TableCell>Status Name</TableCell>
              <TableCell>Updated Date</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {takedownStatusHistory.loading ? (
              <TableRow>
                <LoaderWrapper size={25} />
              </TableRow>
            ) : statusHistory && statusHistory.length > 0 ? (
              statusHistory.map((historyItem: any, index: number) => (
                <TableRow key={index}>
                  <TableCell>{historyItem.takedown_id}</TableCell>
                  <TableCell>
                    {findStatusNameById(historyItem?.status_id)}
                  </TableCell>
                  <TableCell>
                    {new Date(historyItem.updated_at).toLocaleString()}
                  </TableCell>
                </TableRow>
              ))
            ) : (
              <TableRow>
                <TableCell colSpan={3}>No history found</TableCell>
              </TableRow>
            )}
          </TableBody>
        </Table>
      </TableContainer>
    </Box>
  );
};

export default TakedownStatusHistory;
