import React, { useEffect, useState } from 'react';
import {
  Box,
  IconButton,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Typography,
  styled,
} from '@mui/material';
import { useSelector, useDispatch } from 'react-redux';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import { CustomTableCell } from '../../CompaniesList/Style/CompaniesList.style';
import { Button } from '../../../components';
import AddUserModal from './AddUserModal';
import { NoDataMessage, formatedDate, LoaderWrapper } from '../../../utils';
import {
  getUserDetailsAction,
  getUserRoleAction,
} from '../../../store/user/action';
import UserDetailsAccordion from './UserDetailsAccordion';

const PaperStyle = {
  backgroundColor: 'white',
  height: 'auto',
  p: 2.5,
};

const FilterWrapper = styled(Box)(() => ({
  display: 'flex',
  marginBottom: '24px',
  justifyContent: 'space-between',
}));

const UsersTab = () => {
  const [openAddDomain, setOpenAddDomain] = useState<boolean>(false);
  const [openAccordionId, setOpenAccordionId] = useState<string | null>(null);

  const dispatch = useDispatch();
  const { getCompanyDetail } = useSelector((state: any) => state.company);
  const getUserList = getCompanyDetail.data?.Users;

  useEffect(() => {
    if (openAccordionId) {
      dispatch(getUserDetailsAction({ id: openAccordionId }));
    }
  }, [dispatch, openAccordionId]);

  const handleOpenAddDomain = () => {
    setOpenAddDomain(true);
    dispatch(getUserRoleAction(''));
  };

  const handleCloseAddDomain = () => {
    setOpenAddDomain(false);
  };

  const handleAccordionToggle = (id: string) => {
    setOpenAccordionId(openAccordionId === id ? null : id);
  };

  return (
    <>
      <Paper elevation={1} sx={PaperStyle}>
        <FilterWrapper>
          <Typography sx={{ fontWeight: 600 }}>Current Users</Typography>
          <Button
            data-testid="add-user-button"
            variant="contained"
            sx={{ background: '#0fd6c2' }}
            onClick={handleOpenAddDomain}>
            Add User
          </Button>
        </FilterWrapper>
        <Table size="small">
          <TableHead>
            <TableRow>
              <CustomTableCell>Firstname</CustomTableCell>
              <CustomTableCell align="left">Lastname</CustomTableCell>
              <CustomTableCell align="left">Username</CustomTableCell>
              <CustomTableCell align="left">Email</CustomTableCell>
              <CustomTableCell align="left">Created</CustomTableCell>
              <CustomTableCell align="left"></CustomTableCell>
            </TableRow>
          </TableHead>
          {getCompanyDetail.loading ? (
            <LoaderWrapper size={25} />
          ) : getUserList.length === 0 ? (
            <NoDataMessage message="No User found" />
          ) : (
            <TableBody>
              {getUserList.map((item: any) => {
                return (
                  <React.Fragment key={item.Id}>
                    <TableRow>
                      <TableCell>{item.Firstname}</TableCell>
                      <TableCell align="left">{item.Lastname}</TableCell>
                      <TableCell align="left">{item.Username}</TableCell>
                      <TableCell align="left">{item.Email}</TableCell>
                      <TableCell align="left">
                        {formatedDate(item.Created)}
                      </TableCell>
                      <TableCell>
                        <IconButton
                          size="small"
                          onClick={() => handleAccordionToggle(item.Id)}>
                          {openAccordionId === item.Id ? (
                            <KeyboardArrowUpIcon />
                          ) : (
                            <KeyboardArrowDownIcon />
                          )}
                        </IconButton>
                      </TableCell>
                    </TableRow>
                    <UserDetailsAccordion
                      openAccordionId={openAccordionId}
                      item={item}
                    />
                  </React.Fragment>
                );
              })}
            </TableBody>
          )}
        </Table>
      </Paper>
      <AddUserModal handleClose={handleCloseAddDomain} open={openAddDomain} />
    </>
  );
};

export default UsersTab;
