import { combineReducers } from 'redux';
import { companyReducer } from './company/reducer';
import { threatReducer } from './threats/reducer';
import { singleThreatReducer } from './singleThreat/reducer';
import { analyticReducer } from './analytic/reducer';
import { noteCommentsReducer } from './noteComments/reducer';
import { takedownReducer } from './takedown/reducer';
import { userReducer } from './user/userReducer';
import { toasterReducer } from './toaster/reducer';
import { activeDefenceReducer } from './activeDefence/reducer';
import { falsePositiveReducer } from './falsePositive/reducer';
import { gsbHistoryReducer } from './gsbHistory/reducer';
import { notificationReducer } from './notification/reducer';

const appReducer = combineReducers({
  company: companyReducer,
  user: userReducer,
  threats: threatReducer,
  takedown: takedownReducer,
  singleThreat: singleThreatReducer,
  noteComments: noteCommentsReducer,
  analytic: analyticReducer,
  toaster: toasterReducer,
  activeDefence: activeDefenceReducer,
  falsePositive: falsePositiveReducer,
  gsbHistory: gsbHistoryReducer,
  notification: notificationReducer,
});

const rootReducer = (state: any, action: any) => {
  return appReducer(state, action);
};

export default rootReducer;
