import { styled } from '@mui/material/styles';
import { Box, FormControl, Select, InputLabel } from '@mui/material';
import { textTransform } from 'html2canvas/dist/types/css/property-descriptors/text-transform';

export const SelectWrapper = styled(Box)(({ theme }) => ({
  width: '100%'
}));

export const StyledFormControl = styled(FormControl)(({ theme }) => ({
  width: '100%'
}));

export const StyledSelect = styled(Select)(({ theme }) => ({
  '& .MuiSelect-select': {
    padding: '8px 12px',
    borderRadius: '8px',
    textTransform: 'capitalize'
  },
  '& .MuiSelect-icon': {
    color: theme.palette.primary.dark
  },
  '& .MuiOutlinedInput-notchedOutline': {
    border: '1px solid #E4E7EC'
  },
  '&:hover .MuiOutlinedInput-notchedOutline': {
    border: '1px solid #E4E7EC'
  },
  '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
    border: '1px solid #E4E7EC'
  }
}));

export const StyledInputLabel = styled(InputLabel)(({ theme }) => ({
  // Add any custom label styles here
}));
