import React, { useState } from 'react';
import { Box, Paper, styled } from '@mui/material';
import { a11yProps } from '../../utils';
import { TabPanel } from '../../components';
import { StyledTab, StyledTabs } from './Style/CompanyDetails.style';
import ProtectedDomainsTab from './Tabs/ProtectedDomainsTab';
import ActivityLogsTab from './Tabs/ActivityLogsTab';
import ChildCompaniesTab from './Tabs/ChildCompaniesTab';
import InformationTab from './Tabs/InformationTab';
import UsersTab from './Tabs/UsersTab';
import KeywordsTab from './Tabs/KeywordsTab';
import ViewDomainDetails from './Tabs/ViewDomainDetails';

export const CustomTabPanel = styled(TabPanel)(() => ({
  padding: '16px 0',
}));

const PaperStyle = {
  backgroundColor: 'white',
  height: 'auto',
  p: 2.5,
};

const CompanyDetailsTabs = ({
  handleChangeDomainPage,
  domainCurrentPage,
  domainCount,
  handleChangeDomainName,
  searchDomainName,
}: any) => {
  const [activeTab, setActiveTab] = useState<number>(0);
  const [selectedDomain, setSelectedDomain] = useState<string | null>(null);

  const handleTabChange = (newValue: number) => {
    setActiveTab(newValue);
  };

  const handleDomainClick = (item: any) => {
    setSelectedDomain(item);
  };

  return (
    <>
      <Box sx={{ width: '100%' }}>
        <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
          <StyledTabs
            data-testid="company-details-tabs"
            value={activeTab}
            onChange={(_, newValue) => handleTabChange(newValue)}>
            <StyledTab label="Protected Domains" {...a11yProps(0)} />
            <StyledTab label="Activity Logs" {...a11yProps(1)} />
            <StyledTab label="Child Companies" {...a11yProps(2)} />
            <StyledTab label="Information" {...a11yProps(3)} />
            <StyledTab label="Users" {...a11yProps(4)} />
            <StyledTab label="Keywords" {...a11yProps(5)} />
          </StyledTabs>
        </Box>
        <CustomTabPanel value={activeTab} index={0}>
          <Paper elevation={1} sx={PaperStyle}>
            {selectedDomain ? (
              <ViewDomainDetails
                selectedDomain={selectedDomain}
                onBack={() => setSelectedDomain(null)}
              />
            ) : (
              <ProtectedDomainsTab
                searchDomainName={searchDomainName}
                handleChangeDomainName={handleChangeDomainName}
                domainCurrentPage={domainCurrentPage}
                onDomainClick={handleDomainClick}
                domainCount={domainCount}
                handleChangeDomainPage={handleChangeDomainPage}
              />
            )}
          </Paper>
        </CustomTabPanel>
        <CustomTabPanel value={activeTab} index={1}>
          <ActivityLogsTab />
        </CustomTabPanel>
        <CustomTabPanel value={activeTab} index={2}>
          <ChildCompaniesTab />
        </CustomTabPanel>
        <CustomTabPanel value={activeTab} index={3}>
          <InformationTab />
        </CustomTabPanel>
        <CustomTabPanel value={activeTab} index={4}>
          <UsersTab />
        </CustomTabPanel>
        <CustomTabPanel value={activeTab} index={5}>
          <KeywordsTab />
        </CustomTabPanel>
      </Box>
    </>
  );
};

export default CompanyDetailsTabs;
