export const SearchBarEndAdornment = {
  padding: 0,
  marginRight: 0,
  position: 'absolute',
  right: 10,
  top: '50%',
  transform: 'translateY(-50%)',
};

export const SearchBarStyle = {
  '& .MuiOutlinedInput-root': {
    borderRadius: '8px',
    background: 'white'
  },
  mr: 2,
};
