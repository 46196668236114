import React from 'react';
import { IconButton, Typography } from '@mui/material';
import TableChartIcon from '@mui/icons-material/TableChart';
import ViewModuleIcon from '@mui/icons-material/ViewModule';
import { ThreatViewTitle, ThreatViewWrapper } from './Style/Home.style';

const ThreatViewToggle = ({ viewPreference, handleViewToggle }: any) => {
  return (
      <ThreatViewWrapper data-testid="threat-view-toggle">
        <ThreatViewTitle onClick={() => handleViewToggle('card')}>
          <IconButton data-testid="card-view-button">
            <ViewModuleIcon
              color={viewPreference === 'card' ? 'primary' : 'inherit'}
            />
          </IconButton>
          <Typography
            variant="body1"
            color={viewPreference === 'card' ? 'primary' : 'textSecondary'}>
          </Typography>
        </ThreatViewTitle>
        <ThreatViewTitle onClick={() => handleViewToggle('table')}>
          <IconButton data-testid="table-view-button">
            <TableChartIcon
              color={viewPreference === 'table' ? 'primary' : 'inherit'}
            />
          </IconButton>
          <Typography
            variant="body1"
            color={viewPreference === 'table' ? 'primary' : 'textSecondary'}>
          </Typography>
        </ThreatViewTitle>
      </ThreatViewWrapper>
  );
};

export default ThreatViewToggle;
