export const GET_SERVICE_BEGIN = 'GET_SERVICE_BEGIN';
export const GET_SERVICE_SUCCESS = 'GET_SERVICE_SUCCESS';
export const GET_SERVICE_FAILURE = 'GET_SERVICE_FAILURE';

export const GET_COMPANY_BEGIN = 'GET_COMPANY_BEGIN';
export const GET_COMPANY_SUCCESS = 'GET_COMPANY_SUCCESS';
export const GET_COMPANY_FAILURE = 'GET_COMPANY_FAILURE';

export const GET_STATUS_BEGIN = 'GET_STATUS_BEGIN';
export const GET_STATUS_SUCCESS = 'GET_STATUS_SUCCESS';
export const GET_STATUS_FAILURE = 'GET_STATUS_FAILURE';

export const GET_THREAT_BEGIN = 'GET_THREAT_BEGIN';
export const GET_THREAT_SUCCESS = 'GET_THREAT_SUCCESS';
export const GET_THREAT_FAILURE = 'GET_THREAT_FAILURE';

export const GET_THREAT_DETAIL_BEGIN = 'GET_THREAT_DETAIL_BEGIN';
export const GET_THREAT_DETAIL_SUCCESS = 'GET_THREAT_DETAIL_SUCCESS';
export const GET_THREAT_DETAIL_FAILURE = 'GET_THREAT_DETAIL_FAILURE';

export const GET_TAKEDOWN_THREAT_DETAIL_BEGIN =
  'GET_TAKEDOWN_THREAT_DETAIL_BEGIN';
export const GET_TAKEDOWN_THREAT_DETAIL_SUCCESS =
  'GET_TAKEDOWN_THREAT_DETAIL_SUCCESS';
export const GET_TAKEDOWN_THREAT_DETAIL_FAILURE =
  'GET_TAKEDOWN_THREAT_DETAIL_FAILURE';

export const GET_STATUS_TAKEDOWN_BEGIN = 'GET_STATUS_TAKEDOWN_BEGIN';
export const GET_STATUS_TAKEDOWN_SUCCESS = 'GET_STATUS_TAKEDOWN_SUCESS';
export const GET_STATUS_TAKEDOWN_FAILURE = 'GET_STATUS_TAKEDOWN_FAILURE';

export const TAKEDOWN_STATUS_HISTRORY_BEGIN = 'TAKEDOWN_STATUS_HISTRORY_BEGIN';
export const TAKEDOWN_STATUS_HISTRORY_SUCCESS =
  'TAKEDOWN_STATUS_HISTRORY_SUCESS';
export const TAKEDOWN_STATUS_HISTRORY_FAILURE =
  'TAKEDOWN_STATUS_HISTRORY_FAILURE';

export const GET_SUBDOMAINS_BEGIN = 'GET_SUBDOMAINS_BEGIN';
export const GET_SUBDOMAINS_SUCCESS = 'GET_SUBDOMAINS_SUCCESS';
export const GET_SUBDOMAINS_FAILURE = 'GET_SUBDOMAINS_FAILURE';

export const GET_NETWORK_BEGIN = 'GET_NETWORK_BEGIN';
export const GET_NETWORK_SUCCESS = 'GET_NETWORK_SUCCESS';
export const GET_NETWORK_FAILURE = 'GET_NETWORK_FAILURE';

export const GET_TAKEDOWNLIST_BEGIN = 'GET_TAKEDOWNLIST_BEGIN';
export const GET_TAKEDOWNLIST_SUCCESS = 'GET_TAKEDOWNLIST_SUCCESS';
export const GET_TAKEDOWNLIST_FAILURE = 'GET_TAKEDOWNLIST_FAILURE';

export const CHANGE_STATUSLABEL_BEGIN = 'CHANGE_STATUSLABEL_BEGIN';
export const CHANGE_STATUSLABEL_SUCCESS = 'CHANGE_STATUSLABEL_SUCCESS';
export const CHANGE_STATUSLABEL_FAILURE = 'CHANGE_STATUSLABEL_FAILURE';

export const CHANGE_BULK_STATUS_BEGIN = 'CHANGE_BULK_STATUS_BEGIN';
export const CHANGE_BULK_STATUS_SUCCESS = 'CHANGE_BULK_STATUS_SUCCESS';
export const CHANGE_BULK_STATUS_FAILURE = 'CHANGE_BULK_STATUS_FAILURE';

export const CREATE_THREAT_BEGIN = 'CREATE_THREAT_BEGIN';
export const CREATE_THREAT_SUCCESS = 'CREATE_THREAT_SUCCESS';
export const CREATE_THREAT_FAILURE = 'CREATE_THREAT_FAILURE';

export const GET_HUBSPORTID_BEGIN = 'GET_HUBSPORTID_BEGIN';
export const GET_HUBSPORTID_SUCCESS = 'GET_HUBSPORTID_SUCCESS';
export const GET_HUBSPORTID_FAILURE = 'GET_HUBSPORTID_FAILURE';

export const CREATE_TAKEDOWN_BEGIN = 'CREATE_TAKEDOWN_BEGIN';
export const CREATE_TAKEDOWN_SUCCESS = 'CREATE_TAKEDOWN_SUCCESS';
export const CREATE_TAKEDOWN_FAILURE = 'CREATE_TAKEDOWN_FAILURE';

export const COMPLETE_TAKEDOWN_BEGIN = 'COMPLETE_TAKEDOWN_BEGIN';
export const COMPLETE_TAKEDOWN_SUCCESS = 'COMPLETE_TAKEDOWN_SUCCESS';
export const COMPLETE_TAKEDOWN_FAILURE = 'COMPLETE_TAKEDOWN_FAILURE';

export const GET_DOMAINS_LIST_BEGIN = 'GET_DOMAINS_LIST_BEGIN';
export const GET_DOMAINS_LIST_SUCCESS = 'GET_DOMAINS_LIST_SUCCESS';
export const GET_DOMAINS_LIST_FAILURE = 'GET_DOMAINS_LIST_FAILURE';

export const GET_HISTORY_STATUSES_BEGIN = 'GET_HISTORY_STATUSES_BEGIN';
export const GET_HISTORY_STATUSES_SUCCESS = 'GET_HISTORY_STATUSES_SUCCESS';
export const GET_HISTORY_STATUSES_FAILURE = 'GET_HISTORY_STATUSES_FAILURE';

export const RESET_THREAT_LIST = 'RESET_THREAT_LIST';
export const TOGGLE_INFINITE_SCROLL = 'TOGGLE_INFINITE_SCROLL';

export const SET_ANALYTIC_DATA = `SET_ANALYTIC_DATA`;

export const SET_DATE_PICKER_PERSIST_VALUES = 'SET_DATE_PICKER_PERSIST_VALUES';
export const SET_COMPANY_PERSIST_VALUES = 'SET_COMPANY_PERSIST_VALUES';
export const SET_STATUS_PERSIST_VALUES = 'SET_STATUS_PERSIST_VALUES';
export const SET_REVIEW_STATUS_PERSIST_VALUES =
  'SET_REVIEW_STATUS_PERSIST_VALUES';
export const SET_PAGE_SIZE_PERSIST_VALUES = 'SET_PAGE_SIZE_PERSIST_VALUES';

export const GET_COMPANYLIST_BEGIN = 'GET_COMPANYLIST_BEGIN';
export const GET_COMPANYLIST_SUCCESS = 'GET_COMPANYLIST_SUCCESS';
export const GET_COMPANYLIST_FAILURE = 'GET_COMPANYLIST_FAILURE';

export const DELETE_COMPANY_BEGIN = 'DELETE_COMPANY_BEGIN';
export const DELETE_COMPANY_SUCCESS = 'DELETE_COMPANY_SUCCESS';
export const DELETE_COMPANY_FAILURE = 'DELETE_COMPANY_FAILURE';

export const ADD_COMPANY_BEGIN = 'ADD_COMPANY_BEGIN';
export const ADD_COMPANY_SUCCESS = 'ADD_COMPANY_SUCCESS';
export const ADD_COMPANY_FAILURE = 'ADD_COMPANY_FAILURE';

export const GET_COMPANYDETAIL_BEGIN = 'GET_COMPANYDETAIL_BEGIN';
export const GET_COMPANYDETAIL_SUCCESS = 'GET_COMPANYDETAIL_SUCCESS';
export const GET_COMPANYDETAIL_FAILURE = 'GET_COMPANYDETAIL_FAILURE';

export const GET_PROTECTED_DOMAINS_BEGIN = 'GET_PROTECTED_DOMAINS_BEGIN';
export const GET_PROTECTED_DOMAINS_SUCCESS = 'GET_PROTECTED_DOMAINS_SUCCESS';
export const GET_PROTECTED_DOMAINS_FAILURE = 'GET_PROTECTED_DOMAINS_FAILURE';

export const ADD_PROTECTED_DOMAINS_BEGIN = 'ADD_PROTECTED_DOMAINS_BEGIN';
export const ADD_PROTECTED_DOMAINS_SUCCESS = 'ADD_PROTECTED_DOMAINS_SUCCESS';
export const ADD_PROTECTED_DOMAINS_FAILURE = 'ADD_PROTECTED_DOMAINS_FAILURE';

export const DOMAIN_UNPROTECT_BEGIN = 'DOMAIN_UNPROTECT_BEGIN';
export const DOMAIN_UNPROTECT_SUCCESS = 'DOMAIN_UNPROTECT_SUCCESS';
export const DOMAIN_UNPROTECT_FAILURE = 'DOMAIN_UNPROTECT_FAILURE';

export const ADDINFORMATION_GENERIC_BEGIN = 'INFORMATION_GENERIC_BEGIN';
export const ADDINFORMATION_GENERIC_SUCCESS = 'INFORMATION_GENERIC_SUCCESS';
export const ADDINFORMATION_GENERIC_FAILURE = 'INFORMATION_GENERIC_FAILURE';

export const CHANGE_STATUS_DOMAIN_BEGIN = 'CHANGE_STATUS_DOMAIN_BEGIN';
export const CHANGE_STATUS_DOMAIN_SUCCESS = 'CHANGE_STATUS_DOMAIN_SUCCESS';
export const CHANGE_STATUS_DOMAIN_FAILURE = 'CHANGE_STATUS_DOMAIN_FAILURE';

export const GET_DOMAIN_KEYWORDS_BEGIN = 'GET_DOMAIN_KEYWORDS_BEGIN';
export const GET_DOMAIN_KEYWORDS_SUCCESS = 'GET_DOMAIN_KEYWORDS_SUCCESS';
export const GET_DOMAIN_KEYWORDS_FAILURE = 'GET_DOMAIN_KEYWORDS_FAILURE';

export const ADD_DOMAIN_KEYWORDS_BEGIN = 'ADD_DOMAIN_KEYWORDS_BEGIN';
export const ADD_DOMAIN_KEYWORDS_SUCCESS = 'ADD_DOMAIN_KEYWORDS_SUCCESS';
export const ADD_DOMAIN_KEYWORDS_FAILURE = 'ADD_DOMAIN_KEYWORDS_FAILURE';

export const ADD_CHILD_COMPANY_BEGIN = 'ADD_CHILD_COMPANY_BEGIN';
export const ADD_CHILD_COMPANY_SUCCESS = 'ADD_CHILD_COMPANY_SUCCESS';
export const ADD_CHILD_COMPANY_FAILURE = 'ADD_CHILD_COMPANY_FAILURE';

export const UNSET_PARENT_COMPANY_BEGIN = 'UNSET_PARENT_COMPANY_BEGIN';
export const UNSET_PARENT_COMPANY_SUCCESS = 'UNSET_PARENT_COMPANY_SUCCESS';
export const UNSET_PARENT_COMPANY_FAILURE = 'UNSET_PARENT_COMPANY_FAILURE';

export const GET_NOTES_VALUES_BEGIN = 'GET_NOTES_VALUES_BEGIN';
export const GET_NOTES_VALUES_SUCCESS = 'GET_NOTES_VALUES_SUCCESS';
export const GET_NOTES_VALUES_FAILURE = 'GET_NOTES_VALUES_FAILURE';

export const GET_NOTES_ID_BEGIN = 'GET_NOTES_ID_BEGIN';
export const GET_NOTES_ID_SUCCESS = 'GET_NOTES_ID_SUCCESS';
export const GET_NOTES_ID_FAILURE = 'GET_NOTES_ID_FAILURE';

export const CREATE_NOTES_BEGIN = 'CREATE_NOTES_BEGIN';
export const CREATE_NOTES_SUCCESS = 'CREATE_NOTES_SUCCESS';
export const CREATE_NOTES_FAILURE = 'CREATE_NOTES_FAILURE';

export const DELETE_NOTES_BEGIN = 'DELETE_NOTES_BEGIN';
export const DELETE_NOTES_SUCCESS = 'DELETE_NOTES_SUCCESS';
export const DELETE_NOTES_FAILURE = 'DELETE_NOTES_FAILURE';

export const GET_COMMENTS_BEGIN = 'GET_COMMENTS_BEGIN';
export const GET_COMMENTS_SUCCESS = 'GET_COMMENTS_SUCCESS';
export const GET_COMMENTS_FAILURE = 'GET_COMMENTS_FAILURE';

export const CREATE_COMMENTS_BEGIN = 'CREATE_COMMENTS_BEGIN';
export const CREATE_COMMENTS_SUCCESS = 'CREATE_COMMENTS_SUCCESS';
export const CREATE_THREATCOMMENTS_FAILURE = 'CREATE_THREATCOMMENTS_FAILURE';

export const UPDATE_COMMENTS_BEGIN = 'UPDATE_COMMENTS_BEGIN';
export const UPDATE_COMMENTS_SUCCESS = 'UPDATE_COMMENTS_SUCCESS';
export const UPDATE_COMMENTS_FAILURE = 'UPDATE_COMMENTS_FAILURE';

export const GET_NOTEVALUEID_BEGIN = 'GET_NOTEVALUEID_BEGIN';
export const GET_NOTEVALUEID_SUCCESS = 'GET_NOTEVALUEID_SUCCESS';
export const GET_NOTEVALUEID_FAILURE = 'GET_NOTEVALUEID_FAILURE';

export const UPDATE_COMPANY_DETAILS_BEGIN = 'UPDATE_COMPANY_DETAILS_BEGIN';
export const UPDATE_COMPANY_DETAILS_SUCCESS = 'UPDATE_COMPANY_DETAILS_SUCCESS';
export const UPDATE_COMPANY_DETAILS_FAILURE = 'UPDATE_COMPANY_DETAILS_FAILURE';

export const UPDATE_COMPANY_INDUSTRY_BEGIN = 'UPDATE_COMPANY_INDUSTRY_BEGIN';
export const UPDATE_COMPANY_INDUSTRY_SUCCESS =
  'UPDATE_COMPANY_INDUSTRY_SUCCESS';
export const UPDATE_COMPANY_INDUSTRY_FAILURE =
  'UPDATE_COMPANY_INDUSTRY_FAILURE';

export const UPDATE_COMPANY_SERVICE_BEGIN = 'UPDATE_COMPANY_SERVICE_BEGIN';
export const UPDATE_COMPANY_SERVICE_SUCCESS = 'UPDATE_COMPANY_SERVICE_SUCCESS';
export const UPDATE_COMPANY_SERVICE_FAILURE = 'UPDATE_COMPANY_SERVICE_FAILURE';

export const GET_USER_DETAILS_BEGIN = 'GET_USER_DETAILS_BEGIN';
export const GET_USER_DETAILS_SUCCESS = 'GET_USER_DETAILS_SUCCESS';
export const GET_USER_DETAILS_FAILURE = 'GET_USER_DETAILS_FAILURE';
export const SET_VIEW_PREFERENCE = 'SET_VIEW_PREFERENCE';

export const GET_USER_TOKEN_BEGIN = 'GET_USER_TOKEN_BEGIN';
export const GET_USER_TOKEN_SUCCESS = 'GET_USER_TOKEN_SUCCESS';
export const GET_USER_TOKEN_FAILURE = 'GET_USER_TOKEN_FAILURE';

export const GET_USER_ROLE_BEGIN = 'GET_USER_ROLE_BEGIN';
export const GET_USER_ROLE_SUCCESS = 'GET_USER_ROLE_SUCCESS';
export const GET_USER_ROLE_FAILURE = 'GET_USER_ROLE_FAILURE';

export const ADD_USER_SAVE_BEGIN = 'ADD_USER_SAVE_BEGIN';
export const ADD_USER_SAVE_SUCCESS = 'ADD_USER_SAVE_SUCCESS';
export const ADD_USER_SAVE_FAILURE = 'ADD_USER_SAVE_FAILURE';

export const GET_KEYWORDS_BEGIN = 'GET_KEYWORDS_BEGIN';
export const GET_KEYWORDS_SUCCESS = 'GET_KEYWORDS_SUCCESS';
export const GET_KEYWORDS_FAILURE = 'GET_KEYWORDS_FAILURE';

export const DELETE_COMMENTS_BEGIN = 'DELETE_COMMENTS_BEGIN';
export const DELETE_COMMENTS_SUCCESS = 'DELETE_COMMENTS_SUCCESS';
export const DELETE_COMMENTS_FAILURE = 'DELETE_COMMENTS_FAILURE';

export const UPDATE_ENRICH_TAKEDOWN_BEGIN = 'UPDATE_ENRICH_TAKEDOWN_BEGIN';
export const UPDATE_ENRICH_TAKEDOWN_SUCCESS = 'UPDATE_ENRICH_TAKEDOWN_SUCCESS';
export const UPDATE_ENRICH_TAKEDOWN_FAILURE = 'UPDATE_ENRICH_TAKEDOWN_FAILURE';

export const GET_NETWORK_DISRUPTION_BEGIN = 'GET_NETWORK_DISRUPTION_BEGIN';
export const GET_NETWORK_DISRUPTION_SUCCESS = 'GET_NETWORK_DISRUPTION_SUCCESS';
export const GET_NETWORK_DISRUPTION_FAILURE = 'GET_NETWORK_DISRUPTION_FAILURE';

export const GET_NETWORK_DISRUPTION_THREAT_ID_BEGIN =
  'GET_NETWORK_DISRUPTION_THREAT_ID_BEGIN';
export const GET_NETWORK_DISRUPTION_THREAT_ID_SUCCESS =
  'GET_NETWORK_DISRUPTION_THREAT_ID_SUCCESS';
export const GET_NETWORK_DISRUPTION_THREAT_ID_FAILURE =
  'GET_NETWORK_DISRUPTION_THREAT_ID_FAILURE';

export const GET_NETWORK_DISRUPTION_CHECK_THREAT_BEGIN =
  'GET_NETWORK_DISRUPTION_CHECK_THREAT_BEGIN';
export const GET_NETWORK_DISRUPTION_CHECK_THREAT_SUCCESS =
  'GET_NETWORK_DISRUPTION_CHECK_THREAT_SUCCESS';
export const GET_NETWORK_DISRUPTION_CHECK_THREAT_FAILURE =
  'GET_NETWORK_DISRUPTION_CHECK_THREAT_FAILURE';

export const GET_REGION_CODE_BEGIN = 'GET_REGION_CODE_BEGIN';
export const GET_REGION_CODE_SUCCESS = 'GET_REGION_CODE_SUCCESS';
export const GET_REGION_CODE_FAILURE = 'GET_REGION_CODE_FAILURE';

export const GET_PLATFORM_BEGIN = 'GET_PLATFORM_BEGIN';
export const GET_PLATFORM_SUCCESS = 'GET_PLATFORM_SUCCESS';
export const GET_PLATFORM_FAILURE = 'GET_PLATFORM_FAILURE';

export const GET_LABLE_BEGIN = 'GET_LABLE_BEGIN';
export const GET_LABLE_SUCCESS = 'GET_LABLE_SUCCESS';
export const GET_LABLE_FAILURE = 'GET_LABLE_FAILURE';

export const SUBMIT_TO_GSB_BEGIN = 'SUBMIT_TO_GSB_BEGIN';
export const SUBMIT_TO_GSB_SUCCESS = 'SUBMIT_TO_GSB_SUCCESS';
export const SUBMIT_TO_GSB_FAILURE = 'SUBMIT_TO_GSB_FAILURE';

export const SHOW_TOAST = 'SHOW_TOAST';
export const HIDE_TOAST = 'HIDE_TOAST';
export const SET_EXCLUDE_COMPANY = 'SET_EXCLUDE_COMPANY';

export const SET_SERVICES_DATA = 'SET_SERVICES_DATA';
export const SET_SOURCES_DATA = 'SET_SOURCES_DATA';
export const SET_DATE_PICKER_STARTDATE = 'SET_DATE_PICKER_STARTDATE';
export const SET_DATE_PICKER_ENDDATE = 'SET_DATE_PICKER_ENDDATE';

export const GET_TAKEDOWN_TYPES_BEGIN = 'GET_TAKEDOWN_TYPES_BEGIN';
export const GET_TAKEDOWN_TYPES_SUCCESS = 'GET_TAKEDOWN_TYPES_SUCCESS';
export const GET_TAKEDOWN_TYPES_FAILURE = 'GET_TAKEDOWN_TYPES_FAILURE';

export const GET_ACTIVE_DEFENCE_BEGIN = 'GET_ACTIVE_DEFENCE_BEGIN';
export const GET_ACTIVE_DEFENCE_SUCCESS = 'GET_ACTIVE_DEFENCE_SUCCESS';
export const GET_ACTIVE_DEFENCE_FAILURE = 'GET_ACTIVE_DEFENCE_FAILURE';

export const GET_FALSE_POSITIVE_BEGIN = 'GET_FALSE_POSITIVE_BEGIN';
export const GET_FALSE_POSITIVE_SUCCESS = 'GET_FALSE_POSITIVE_SUCCESS';
export const GET_FALSE_POSITIVE_FAILURE = 'GET_FALSE_POSITIVE_FAILURE';

export const GET_WHOIS_RECORDS_BEGIN = 'GET_WHOIS_RECORDS_BEGIN';
export const GET_WHOIS_RECORDS_SUCCESS = 'GET_WHOIS_RECORDS_SUCCESS';
export const GET_WHOIS_RECORDS_FAILURE = 'GET_WHOIS_RECORDS_FAILURE';

export const GET_SSL_RECORDS_BEGIN = 'GET_SSL_RECORDS_BEGIN';
export const GET_SSL_RECORDS_SUCCESS = 'GET_SSL_RECORDS_SUCCESS';
export const GET_SSL_RECORDS_FAILURE = 'GET_SSL_RECORDS_FAILURE';

export const GET_DNS_RECORDS_BEGIN = 'GET_DNS_RECORDS_BEGIN';
export const GET_DNS_RECORDS_SUCCESS = 'GET_DNS_RECORDS_SUCCESS';
export const GET_DNS_RECORDS_FAILURE = 'GET_DNS_RECORDS_FAILURE';

export const GET_GSB_HISTORY_BEGIN = 'GET_GSB_HISTORY_BEGIN';
export const GET_GSB_HISTORY_SUCCESS = 'GET_GSB_HISTORY_SUCCESS';
export const GET_GSB_HISTORY_FAILURE = 'GET_GSB_HISTORY_FAILURE';

export const UPDATE_DOMAIN_PROGRESS = 'UPDATE_DOMAIN_PROGRESS';
export const ADD_NOTIFICATION = 'ADD_NOTIFICATION';
