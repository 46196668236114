import {
  configureStore,
  getDefaultMiddleware,
  Middleware,
} from '@reduxjs/toolkit';
import { AnyAction, Dispatch } from 'redux';
import createSagaMiddleware from 'redux-saga';
import storage from 'redux-persist/lib/storage';
import { persistStore, persistReducer } from 'redux-persist';
import logger from 'redux-logger';
import rootReducer from './rootReducer';
import { rootSaga } from './rootSaga';

const sagaMiddleware = createSagaMiddleware();

const persistConfig = {
  key: 'root',
  storage,
};
const persistedReducer = persistReducer(persistConfig, rootReducer);

const store = configureStore({
  reducer: persistedReducer,
  middleware: getDefaultMiddleware => {
    const middleware: Middleware<unknown, any, Dispatch<AnyAction>>[] = [
      sagaMiddleware,
      // logger as Middleware<unknown, any, Dispatch<AnyAction>>,
    ];
    return middleware as Middleware<unknown, any, Dispatch<AnyAction>>[];
  },
});

sagaMiddleware.run(rootSaga);
export default store;

export const persistor = persistStore(store);
// export type AppState = ReturnType<typeof rootReducer>;
