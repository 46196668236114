import React from 'react';
import { IconButton } from '@mui/material';
import { Clear as ClearIcon } from '@mui/icons-material';
import InputAdornment from '@mui/material/InputAdornment';
import Magnify from 'mdi-material-ui/Magnify';
import TextField from '@mui/material/TextField';
import { SearchBarProps } from '../../helpers/types';
import GradientButton from '../Button/GradientButton';
import { SearchBarEndAdornment, SearchBarStyle } from './Searchbar.style';
const SearchBar = ({
  size,
  handleChange,
  handleSearch,
  searchValue,
  handleClearSearch,
}: SearchBarProps) => {
  const isSearchDisabled = !searchValue.trim();

  return (
    <>
      <TextField
        size={size}
        onChange={handleChange}
        value={searchValue}
        sx={SearchBarStyle}
        placeholder="Search"
        InputProps={{
          startAdornment: (
            <InputAdornment position="start">
              <Magnify fontSize="small" />
            </InputAdornment>
          ),
          endAdornment: searchValue && (
            <IconButton onClick={handleClearSearch} sx={SearchBarEndAdornment}>
              <ClearIcon />
            </IconButton>
          ),
        }}
      />
      <GradientButton
        data-testid="search-button"
        variant="contained"
        label="Search"
        onClick={handleSearch}
        disabled={isSearchDisabled}
      />
    </>
  );
};

export default SearchBar;
