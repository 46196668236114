import { all, takeLatest, takeEvery } from 'redux-saga/effects';
import {
  GET_COMPANY_BEGIN,
  GET_SERVICE_BEGIN,
  GET_STATUS_BEGIN,
  GET_THREAT_BEGIN,
  GET_TAKEDOWNLIST_BEGIN,
  CHANGE_STATUSLABEL_BEGIN,
  CREATE_THREAT_BEGIN,
  GET_HUBSPORTID_BEGIN,
  CREATE_TAKEDOWN_BEGIN,
  COMPLETE_TAKEDOWN_BEGIN,
  GET_DOMAINS_LIST_BEGIN,
  GET_HISTORY_STATUSES_BEGIN,
  GET_SUBDOMAINS_BEGIN,
  GET_COMPANYLIST_BEGIN,
  ADD_COMPANY_BEGIN,
  GET_COMPANYDETAIL_BEGIN,
  GET_PROTECTED_DOMAINS_BEGIN,
  ADD_PROTECTED_DOMAINS_BEGIN,
  ADD_CHILD_COMPANY_BEGIN,
  GET_NOTES_ID_BEGIN,
  GET_NOTES_VALUES_BEGIN,
  GET_COMMENTS_BEGIN,
  CREATE_COMMENTS_BEGIN,
  CREATE_NOTES_BEGIN,
  DELETE_NOTES_BEGIN,
  UPDATE_COMMENTS_BEGIN,
  UPDATE_COMPANY_DETAILS_BEGIN,
  UPDATE_COMPANY_INDUSTRY_BEGIN,
  GET_USER_DETAILS_BEGIN,
  GET_USER_ROLE_BEGIN,
  ADD_USER_SAVE_BEGIN,
  GET_KEYWORDS_BEGIN,
  UPDATE_COMPANY_SERVICE_BEGIN,
  DELETE_COMMENTS_BEGIN,
  DELETE_COMPANY_BEGIN,
  DOMAIN_UNPROTECT_BEGIN,
  CHANGE_STATUS_DOMAIN_BEGIN,
  GET_DOMAIN_KEYWORDS_BEGIN,
  ADD_DOMAIN_KEYWORDS_BEGIN,
  UNSET_PARENT_COMPANY_BEGIN,
  ADDINFORMATION_GENERIC_BEGIN,
  GET_THREAT_DETAIL_BEGIN,
  GET_TAKEDOWN_THREAT_DETAIL_BEGIN,
  GET_STATUS_TAKEDOWN_BEGIN,
  GET_NETWORK_BEGIN,
  UPDATE_ENRICH_TAKEDOWN_BEGIN,
  CHANGE_BULK_STATUS_BEGIN,
  TAKEDOWN_STATUS_HISTRORY_BEGIN,
  GET_NETWORK_DISRUPTION_CHECK_THREAT_BEGIN,
  GET_REGION_CODE_BEGIN,
  GET_LABLE_BEGIN,
  GET_PLATFORM_BEGIN,
  SUBMIT_TO_GSB_BEGIN,
  GET_TAKEDOWN_TYPES_BEGIN,
  GET_ACTIVE_DEFENCE_BEGIN,
  GET_FALSE_POSITIVE_BEGIN,
  GET_DNS_RECORDS_BEGIN,
  GET_WHOIS_RECORDS_BEGIN,
  GET_SSL_RECORDS_BEGIN,
  GET_GSB_HISTORY_BEGIN,
  GET_USER_TOKEN_BEGIN,
} from './constant';
import {
  addChildCompanySaga,
  addCompanySaga,
  addProtectedDomainsSaga,
  deleteCompanySaga,
  getCompanyDetailSaga,
  getCompanyListSaga,
  getCompanySaga,
  getKeywordsSaga,
  getServiceSaga,
  updateCompanyDetailsSaga,
  updateCompanyIndustrySaga,
  updateCompanyServiceSaga,
  domainUnprotectSaga,
  changeStatusDomainSaga,
  getDomainKeywordsSaga,
  addDomainKeywordsSaga,
  unsetParentCompanySaga,
  addInformationGenericSaga,
} from './company/saga';
import {
  changeBulkStatus,
  changeStatusLabel,
  createThreatSaga,
  getDomainsListSaga,
  getStatusSaga,
  getThreatDetailSaga,
  getThreatSaga,
} from './threats/saga';
import {
  getHistoryStatuesSaga,
  getSubDomainsSaga,
  getNetworkSaga,
  fetchNetworkCheckThreatSaga,
  fetchRegionCodetSaga,
  fetchLableSaga,
  fetchPlatformSaga,
  submitToGsbSaga,
  getDnsRecordsSaga,
  getSSLRecordsSaga,
  getWhoisRecordsSaga,
} from './singleThreat/saga';
import {
  createCommentsSaga,
  createNotesSaga,
  deleteNoteSaga,
  getCommentsSaga,
  getNotesValuesSaga,
  updateCommentsSaga,
  getNoteIdSaga,
  deleteCommentsSaga,
} from './noteComments/saga';
import {
  completeTakedownSaga,
  createTakedownSaga,
  getHubSportIdSaga,
  getTakedownDetailSaga,
  getTakeDownListTypesSaga,
  getTakeDownListSaga,
  getTakedownStatusSaga,
  takedownStatusHistorySaga,
  updateEnrichTakedownSaga,
} from './takedown/saga';
import {
  addUserSaveSaga,
  getUserDetailsSaga,
  getUserRoleSaga,
  userTokenSaga,
} from './user/saga';
import { getActiveDefenceSaga } from './activeDefence/saga';
import { getFalsePostiveSaga } from './falsePositive/saga';
import { getGSBHistorySaga } from './gsbHistory/saga';

export function* rootSaga() {
  yield all([
    takeLatest(GET_SERVICE_BEGIN, getServiceSaga),
    takeLatest(GET_COMPANY_BEGIN, getCompanySaga),
    takeLatest(GET_STATUS_BEGIN, getStatusSaga),
    takeLatest(GET_THREAT_BEGIN, getThreatSaga),
    takeLatest(GET_THREAT_DETAIL_BEGIN, getThreatDetailSaga),
    takeLatest(GET_SUBDOMAINS_BEGIN, getSubDomainsSaga),
    takeLatest(GET_NETWORK_BEGIN, getNetworkSaga),
    takeLatest(GET_DNS_RECORDS_BEGIN, getDnsRecordsSaga),
    takeLatest(GET_SSL_RECORDS_BEGIN, getSSLRecordsSaga),
    takeLatest(GET_WHOIS_RECORDS_BEGIN, getWhoisRecordsSaga),
    takeLatest(GET_TAKEDOWNLIST_BEGIN, getTakeDownListSaga),
    takeLatest(GET_TAKEDOWN_THREAT_DETAIL_BEGIN, getTakedownDetailSaga),
    takeLatest(GET_STATUS_TAKEDOWN_BEGIN, getTakedownStatusSaga),
    takeLatest(TAKEDOWN_STATUS_HISTRORY_BEGIN, takedownStatusHistorySaga),
    takeLatest(UPDATE_ENRICH_TAKEDOWN_BEGIN, updateEnrichTakedownSaga),
    takeEvery(CHANGE_STATUSLABEL_BEGIN, changeStatusLabel),
    takeEvery(CHANGE_BULK_STATUS_BEGIN, changeBulkStatus),
    takeLatest(CREATE_THREAT_BEGIN, createThreatSaga),
    takeLatest(GET_HUBSPORTID_BEGIN, getHubSportIdSaga),
    takeLatest(CREATE_TAKEDOWN_BEGIN, createTakedownSaga),
    takeLatest(COMPLETE_TAKEDOWN_BEGIN, completeTakedownSaga),
    takeLatest(GET_DOMAINS_LIST_BEGIN, getDomainsListSaga),
    takeLatest(GET_HISTORY_STATUSES_BEGIN, getHistoryStatuesSaga),
    takeLatest(GET_COMPANYLIST_BEGIN, getCompanyListSaga),
    takeLatest(DELETE_COMPANY_BEGIN, deleteCompanySaga),
    takeLatest(ADD_COMPANY_BEGIN, addCompanySaga),
    takeLatest(ADD_CHILD_COMPANY_BEGIN, addChildCompanySaga),
    takeLatest(UNSET_PARENT_COMPANY_BEGIN, unsetParentCompanySaga),
    takeLatest(GET_COMPANYDETAIL_BEGIN, getCompanyDetailSaga),
    takeLatest(ADD_PROTECTED_DOMAINS_BEGIN, addProtectedDomainsSaga),
    takeLatest(DOMAIN_UNPROTECT_BEGIN, domainUnprotectSaga),
    takeLatest(ADDINFORMATION_GENERIC_BEGIN, addInformationGenericSaga),
    takeLatest(CHANGE_STATUS_DOMAIN_BEGIN, changeStatusDomainSaga),
    takeLatest(GET_DOMAIN_KEYWORDS_BEGIN, getDomainKeywordsSaga),
    takeLatest(ADD_DOMAIN_KEYWORDS_BEGIN, addDomainKeywordsSaga),
    takeLatest(GET_NOTES_VALUES_BEGIN, getNotesValuesSaga),
    takeLatest(GET_NOTES_ID_BEGIN, getNoteIdSaga),
    takeLatest(CREATE_NOTES_BEGIN, createNotesSaga),
    takeLatest(DELETE_NOTES_BEGIN, deleteNoteSaga),
    takeLatest(GET_COMMENTS_BEGIN, getCommentsSaga),
    takeLatest(CREATE_COMMENTS_BEGIN, createCommentsSaga),
    takeLatest(UPDATE_COMMENTS_BEGIN, updateCommentsSaga),
    takeLatest(UPDATE_COMPANY_DETAILS_BEGIN, updateCompanyDetailsSaga),
    takeLatest(UPDATE_COMPANY_INDUSTRY_BEGIN, updateCompanyIndustrySaga),
    takeLatest(UPDATE_COMPANY_SERVICE_BEGIN, updateCompanyServiceSaga),
    takeLatest(GET_USER_DETAILS_BEGIN, getUserDetailsSaga),
    takeLatest(GET_USER_ROLE_BEGIN, getUserRoleSaga),
    takeLatest(ADD_USER_SAVE_BEGIN, addUserSaveSaga),
    takeLatest(GET_KEYWORDS_BEGIN, getKeywordsSaga),
    takeLatest(DELETE_COMMENTS_BEGIN, deleteCommentsSaga),
    takeLatest(
      GET_NETWORK_DISRUPTION_CHECK_THREAT_BEGIN,
      fetchNetworkCheckThreatSaga,
    ),
    takeLatest(GET_REGION_CODE_BEGIN, fetchRegionCodetSaga),
    takeLatest(GET_LABLE_BEGIN, fetchLableSaga),
    takeLatest(GET_PLATFORM_BEGIN, fetchPlatformSaga),
    takeLatest(SUBMIT_TO_GSB_BEGIN, submitToGsbSaga),
    takeLatest(GET_TAKEDOWN_TYPES_BEGIN, getTakeDownListTypesSaga),
    takeLatest(GET_ACTIVE_DEFENCE_BEGIN, getActiveDefenceSaga),
    takeLatest(GET_FALSE_POSITIVE_BEGIN, getFalsePostiveSaga),
    takeLatest(GET_GSB_HISTORY_BEGIN, getGSBHistorySaga),
    takeLatest(GET_USER_TOKEN_BEGIN, userTokenSaga),
  ]);
}
