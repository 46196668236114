import React from 'react';
import { Box, Paper, styled, TableContainer, Typography } from '@mui/material';
import { useSelector } from 'react-redux';
import { WhoisProps } from '../../../helpers/types';
import { formatedDate } from '../../../utils';

const LabelInfoWrapper = styled(Box)(({ theme }) => ({
  display: 'flex',
  fontFamily: theme.typography.fontFamily
}));

const LinesStyle = {
  margin: '6px 0',
};

const LabelInfo = styled(Box)({
  width: '150px',
});

const RawWhoisData = styled(Box)(({ theme }) => ({
  fontFamily: theme.typography.fontFamily,
  backgroundColor: '#e6e6e6',
  color: 'black',
  padding: '6px 14px',
  display: 'flex',
  justifyContent: 'space-between',
  width: '100%',
}));

const WhoisTab = ({ whois }: any) => {
  const domainInfo = whois?.data?.domain_information;
  const registrantContact = whois?.data?.registrant_contact;
  const administrativeContact = whois?.data?.administrative_contact;

  return (
      <TableContainer
        data-testid="whois-info-container"
        component={Paper}
        sx={{ p: 2, mt: 0.5, width: 'auto' }}>
        <LabelInfoWrapper>
          <LabelInfo>Domain : </LabelInfo>
          <span>{whois?.asset_name || '-'}</span>
        </LabelInfoWrapper>
        <LabelInfoWrapper>
          <LabelInfo>Registrar : </LabelInfo>
          <span>{domainInfo?.registrar || '-'}</span>
        </LabelInfoWrapper>

        <LabelInfoWrapper>
          <LabelInfo>Registered On : </LabelInfo>
          {domainInfo && domainInfo?.registration_date !== null
            ? formatedDate(domainInfo?.registration_date)
            : '-'}
        </LabelInfoWrapper>
        <Box sx={{ m: '16px 8px' }}>
          <Typography variant="subtitle2" sx={{ mb: 1 }}>
            Registrant Contact Data :
          </Typography>
          <RawWhoisData>
            <Box sx={{ display: 'flex' }}>
              <Typography variant="inherit">Name : &nbsp;</Typography>
              <span>{registrantContact?.name || '-'}</span>
            </Box>
            <Box sx={{ width: '50%', display: 'flex' }}>
              <Typography variant="inherit">Organization : &nbsp;</Typography>
              <span>{registrantContact?.organization || '-'}</span>
            </Box>
          </RawWhoisData>
          <RawWhoisData>
            <Box sx={{ display: 'flex' }}>
              <Typography variant="inherit">City : &nbsp;</Typography>
              <span>{registrantContact?.city || '-'}</span>
            </Box>
            <Box sx={{ width: '50%', display: 'flex' }}>
              <Typography variant="inherit">State : &nbsp;</Typography>
              <span>{registrantContact?.state || '-'}</span>
            </Box>
          </RawWhoisData>
          <RawWhoisData>
            <Box sx={{ display: 'flex' }}>
              <Typography variant="inherit">Phone : &nbsp;</Typography>
              <span>{registrantContact?.phone || '-'}</span>
            </Box>
            <Box sx={{ width: '50%', display: 'flex' }}>
              <Typography variant="inherit">Email : &nbsp;</Typography>
              <span>{registrantContact?.email || '-'}</span>
            </Box>
          </RawWhoisData>
        </Box>
        <Box sx={{ m: '16px 8px' }}>
          <Typography variant="subtitle2" sx={{ mb: 1 }}>
            Administrative Contact Data :
          </Typography>
          <RawWhoisData>
            <Box sx={{ display: 'flex' }}>
              <Typography variant="inherit">Name : &nbsp;</Typography>
              <span>{administrativeContact?.name || '-'}</span>
            </Box>
            <Box sx={{ width: '50%', display: 'flex' }}>
              <Typography variant="inherit">Organization : &nbsp;</Typography>
              <span>{administrativeContact?.organization || '-'}</span>
            </Box>
          </RawWhoisData>
          <RawWhoisData>
            <Box sx={{ display: 'flex' }}>
              <Typography variant="inherit">City : &nbsp;</Typography>
              <span>{administrativeContact?.city || '-'}</span>
            </Box>
            <Box sx={{ width: '50%', display: 'flex' }}>
              <Typography variant="inherit">State : &nbsp;</Typography>
              <span>{administrativeContact?.state || '-'}</span>
            </Box>
          </RawWhoisData>
          <RawWhoisData>
            <Box sx={{ display: 'flex' }}>
              <Typography variant="inherit">Phone : &nbsp;</Typography>
              <span>{administrativeContact?.phone || '-'}</span>
            </Box>
            <Box sx={{ width: '50%', display: 'flex' }}>
              <Typography variant="inherit">Email : &nbsp;</Typography>
              <span>{administrativeContact?.email || '-'}</span>
            </Box>
          </RawWhoisData>
        </Box>
      </TableContainer>
  );
};

export default WhoisTab;
