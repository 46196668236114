import React, { useEffect, useState } from 'react';
import { Box, DialogContent, DialogActions, Typography } from '@mui/material';
import { useSelector } from 'react-redux';
import {
  CancelButton,
  CompleteTakedowntDialog,
  CusttomButton,
} from './Style/SingleThreat.style';
import { Input, Loader, SelectOption } from '../../components';
import { CompleteTakedownModalType } from '../../helpers/types';
import { getTakeDownCancellationReasons } from '../../store/takedown';

const CompleteTakedownModal = ({
  handleClose,
  openCompleteTakedown,
  handleCompleteTakedown,
  setTakedownMessage,
  takedownMessage,
  handleChangeStatusTakedown,
  statusTakedownData,
  selectedStatusTakedown,
  handleChangeCancellationReason,
  selectedCancellationStatus
}: CompleteTakedownModalType) => {
  const [cancellationReasonStatus, setCancellationReasonStatus] = useState<any>([])
  const { completeTakedown } = useSelector((state: any) => state.takedown);

  const showDescriptionInput = [
    'final_escalation',
    '3rd_party_unresponsive',
    'completed',
  ].includes(selectedStatusTakedown || '');

  const showCancellationReason = ['cancelled'].includes(
    selectedStatusTakedown || '',
  );

  useEffect(() => {
    const getCancellationList  = async () => {
      try {
        const response = await getTakeDownCancellationReasons()
        if(response?.data){
          const cancellationList = response?.data?.map((data: any) =>  ({
            label: data.reason,
            value: data.id,
          }))
          setCancellationReasonStatus(cancellationList)
        }
      } catch(err) {
        console.log(err)
      }
    }
    getCancellationList()
  }, [])

  return (
    <CompleteTakedowntDialog open={openCompleteTakedown} onClose={handleClose}>
      <DialogContent>
        <Typography variant="subtitle1" fontWeight="bold">
          Do you want to change the status?
        </Typography>
        <Box sx={{ display: 'flex', mt: 2 }}>
          <Typography gutterBottom sx={{ color: 'black', width: '30%' }}>
            Status
          </Typography>
          <SelectOption
            label="status"
            size="small"
            onChange={handleChangeStatusTakedown}
            value={selectedStatusTakedown}
            options={statusTakedownData}
            width="100%"
          />
        </Box>
        {showCancellationReason && (
          <Box sx={{ display: 'flex', mt: 2 }}>
            <Typography gutterBottom sx={{ color: 'black', width: '30%' }}>
              Cancellation Reason
            </Typography>
            <SelectOption
              label="status"
              size="small"
              onChange={handleChangeCancellationReason}
              value={selectedCancellationStatus}
              options={cancellationReasonStatus}
              width="100%"
            />
          </Box>
        )}
        {showDescriptionInput && (
          <Box sx={{ display: 'flex', mt: 2 }}>
            <Typography gutterBottom sx={{ color: 'black', width: '30%' }}>
              Description
            </Typography>
            <Input
              placeholder="Add a message with more details about the takedown completion."
              type="text"
              size="small"
              variant="outlined"
              sx={{ width: '100%' }}
              value={takedownMessage}
              onChange={e => setTakedownMessage(e.target.value)}
            />
          </Box>
        )}
        <DialogActions>
          <CusttomButton 
            data-testid="confirm-takedown-update-button"
            onClick={() => handleCompleteTakedown()}>
            {completeTakedown.loading ? (
              <Loader size={22} />
            ) : (
              'Yes, Update Takedown'
            )}
          </CusttomButton>
          <CancelButton onClick={handleClose}>Cancel</CancelButton>
        </DialogActions>
      </DialogContent>
    </CompleteTakedowntDialog>
  );
};

export default CompleteTakedownModal;
