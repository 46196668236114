import { call, put } from 'redux-saga/effects';
import {
  ADD_USER_SAVE_FAILURE,
  ADD_USER_SAVE_SUCCESS,
  GET_COMPANYDETAIL_SUCCESS,
  GET_USER_DETAILS_FAILURE,
  GET_USER_DETAILS_SUCCESS,
  GET_USER_ROLE_FAILURE,
  GET_USER_ROLE_SUCCESS,
  GET_USER_TOKEN_FAILURE,
  GET_USER_TOKEN_SUCCESS,
} from '../constant';
import {
  addUserAuthorizeApi,
  addUserSaveApi,
  getUserDetailsApi,
  getUserRoleApi,
  userTokenApi,
} from '.';
import { getCompanyDetailApi } from '../company';
import { showToast } from '../toaster/action';

export function* getUserDetailsSaga(action: any): any {
  try {
    const response = yield call(getUserDetailsApi, action?.payload);
    yield put({
      type: GET_USER_DETAILS_SUCCESS,
      payload: response?.data,
    });
  } catch (error: any) {
    yield put({
      type: GET_USER_DETAILS_FAILURE,
      payload: error?.response?.data?.message || 'Something went wrong!',
    });
    yield put(
      showToast(
        error?.response?.data?.message || 'Failed to get detail!',
        'error',
      ),
    );
  }
}

export function* getUserRoleSaga(action: any): any {
  try {
    const response = yield call(getUserRoleApi, action?.payload);
    yield put({
      type: GET_USER_ROLE_SUCCESS,
      payload: response?.data,
    });
  } catch (error: any) {
    yield put({
      type: GET_USER_ROLE_FAILURE,
      payload: error?.response?.data?.message || 'Something went wrong!',
    });
    yield put(
      showToast(
        error?.response?.data?.message || 'Failed to get user role!',
        'error',
      ),
    );
  }
}

export function* addUserSaveSaga(action: any): any {
  try {
    const addUserRes = yield call(addUserSaveApi, action?.payload);
    yield call(addUserAuthorizeApi, {
      userid: addUserRes?.data?.Id,
      roleid: action?.payload?.roleid,
      companyid: action?.payload?.companyid,
    });
    // yield call(addUserJoinApi, {
    //   userid: addUserRes.data.Id,
    //   companyid: action.payload?.companyid,
    // });
    // yield call(addUserSetPasswordApi, {
    //   id: addUserRes.data.Id,
    //   password: action.payload.password,
    // });
    yield put({
      type: ADD_USER_SAVE_SUCCESS,
      payload: addUserRes?.data,
    });
    const getCompanyDetailRes = yield call(getCompanyDetailApi, {
      id: action?.payload?.companyid,
    });
    yield put({
      type: GET_COMPANYDETAIL_SUCCESS,
      payload: getCompanyDetailRes?.data,
    });
  } catch (error: any) {
    yield put({
      type: ADD_USER_SAVE_FAILURE,
      payload: error?.response?.data?.message || 'Something went wrong!',
    });
    yield put(
      showToast(
        error?.response?.data?.message || 'Failed to add user!',
        'error',
      ),
    );
  }
}

export function* userTokenSaga(action: any): any {
  try {
    const response = yield call(userTokenApi, action?.payload);
    yield put({
      type: GET_USER_TOKEN_SUCCESS,
      payload: response?.data,
    });
  } catch (error: any) {
    yield put({
      type: GET_USER_TOKEN_FAILURE,
      payload: error?.response?.data?.message || 'Something went wrong!',
    });
    yield put(
      showToast(
        error?.response?.data?.message || 'Failed to get user role!',
        'error',
      ),
    );
  }
}
