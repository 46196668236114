import { Box, Menu } from '@mui/material';
import { styled } from '@mui/material/styles';

export const StyledFilterCard = styled(Box)({
  boxShadow: '0px 18px 32px 16px rgba(193, 193, 193, 0.16)',
  background: '#ffffff',
  borderRadius: '16px',
  padding: '16px'
});

export const StyledControlsBox = styled(Box)({
  display: 'flex', 
  justifyContent: 'flex-end', 
  gap: 2
});

export const StyledSortMenu = styled(Menu)({
  marginTop: '45px',
  '& span': {
    paddingLeft: '2px',
    color: '#757575',
    fontStyle: 'italic'
  }
});
