import React from 'react';
import { StyledSyncIcon } from './RefreshButton.style';

const RefreshButton = ({
  handleSyncIconClick,
  isDisabled,
  SyncIconStyle,
}: any) => {
  return (
    <StyledSyncIcon
      data-testid="refresh-button-icon"
      onClick={e => {
        e.preventDefault();
        e.stopPropagation();
        if (!isDisabled) handleSyncIconClick();
      }}
      sx={isDisabled ? {} : SyncIconStyle}
      style={{
        color: '#aaa',
        opacity: isDisabled ? 0.5 : 1,
        cursor: isDisabled ? 'not-allowed' : 'pointer',
      }}
    />
  );
};

export default RefreshButton;
