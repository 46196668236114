import React, { useEffect } from 'react';
import { Box, Typography, styled } from '@mui/material';
import { useDispatch, useSelector } from 'react-redux';
import { getHistoryStatusesAction } from '../../store/singleThreat/action';
import { HistoryStatues, ThreatTypes } from '../../helpers/types';
import { Loader } from '../../components';
import { ThreatCard } from './Style/SingleThreat.style';

const LabelInfoWrapper = styled(Box)({
  display: 'flex',
  justifyContent: 'space-between',
});

const LabelInfo = styled(Typography)({
  display: 'flex',
});

const LinesStyle = {
  margin: '4px 0',
};

const LoaderWrapper = styled(Box)({
  alignItems: 'center',
  height: '220px',
  display: 'flex',
  justifyContent: 'center',
});

const StatusHistory = ({
  singleThreat,
}: {
  singleThreat: ThreatTypes | null;
}) => {
  const dispatch = useDispatch();

  const { data: historyStatuses, loading: historyLoading } = useSelector(
    (state: any) => state.singleThreat.historyStatuses,
  );

  useEffect(() => {
    if (
      singleThreat &&
      singleThreat.threat_id &&
      singleThreat.protected_asset_id
    ) {
      dispatch(
        getHistoryStatusesAction({
          d: singleThreat.protected_asset_id,
          t: singleThreat.threat_id,
        }),
      );
    }
  }, [singleThreat?.threat_id && singleThreat?.protected_asset_id]);

  const statusColorMap: Record<string, string> = {
    detected: '#00B2FF',
    owned: '#25badb',
    confirmed: '#50CD89',
    trademark: '#7239EA',
  };

  return (
    <ThreatCard >
      <Typography variant="h6">
        Status History
      </Typography>
      <Box sx={{ mt: 2, overflow: 'auto', height: '220px' }}>
        {historyLoading && (
          <LoaderWrapper>
            <Loader size={20} />
          </LoaderWrapper>
        )}
        {!historyLoading && historyStatuses && historyStatuses.length > 0
          ? historyStatuses
              .sort(
                (a: HistoryStatues, b: HistoryStatues) =>
                  new Date(b.Created).getTime() - new Date(a.Created).getTime(),
              )
              .map((item: HistoryStatues, index: number) => (
                <div key={index}>
                  <LabelInfoWrapper>
                    <LabelInfo>
                      <span
                        style={{
                          color: statusColorMap[item.Status],
                          fontWeight: 500,
                          textTransform: 'capitalize'
                        }}>
                        {item.Status}
                      </span>
                      <span style={{ fontStyle: 'italic', opacity: '0.8' }}>
                        {item.Username ||
                          (['d', 'o'].includes(item.Process ?? '')
                            ? ' : by system'
                            : ' ')}
                      </span>
                    </LabelInfo>
                    <Typography variant="subtitle1">
                      {new Date(item?.Created).toLocaleString()} UTC
                    </Typography>
                  </LabelInfoWrapper>
                </div>
              ))
          : !historyLoading && (
              <LoaderWrapper>
                <Typography>No data found</Typography>
              </LoaderWrapper>
            )}
      </Box>
    </ThreatCard>
  );
};

export default StatusHistory;
