import axios, { AxiosPromise, AxiosRequestConfig } from 'axios';
import { REACT_APP_BASE_URL, REACT_APP_BASE_URL_SCREENSHOTS, REACT_APP_BASE_URL_TAKEDOWN } from '../utils/envVar';

const BASE_URL = REACT_APP_BASE_URL;
const BASE_URL_TAKEDOWN = REACT_APP_BASE_URL_TAKEDOWN;
const BASE_URL_SCREENSHOTS = REACT_APP_BASE_URL_SCREENSHOTS;

export const getToken = () => {
  return window.localStorage.getItem('userToken');
};

export const apiClient = async ({
  url,
  headers,
  ...config
}: AxiosRequestConfig): Promise<AxiosPromise<any>> => {
  const token = getToken();
  try {
    const res = await axios({
      url: `${BASE_URL}/${url}`,
      headers: {
        'Content-Type': 'application/json',
        ...headers,
        Authorization: `Bearer ${token}`,
      },
      ...config,
    });
    return res;
  } catch (error: any) {
    throw error;
  }
};

export const apiClientTakeDown = async ({
  url,
  headers,
  ...config
}: AxiosRequestConfig): Promise<AxiosPromise<any>> => {
  const token = getToken();
  try {
    const res = await axios({
      url: `${BASE_URL_TAKEDOWN}/${url}`,
      headers: {
        'Content-Type': 'application/json',
        ...headers,
        Authorization: `Bearer ${token}`,
      },
      ...config,
    });
    return res;
  } catch (error: any) {
    throw error;
  }
};

export const apiClientScreenshots = async ({
  url,
  headers,
  ...config
}: AxiosRequestConfig): Promise<AxiosPromise<any>> => {
  const token = getToken();
  try {
    const res = await axios({
      url: `${BASE_URL_SCREENSHOTS}/${url}`,
      headers: {
        'Content-Type': 'application/json',
        ...headers,
        Authorization: `Bearer ${token}`,
      },
      ...config,
    });
    return res;
  } catch (error: any) {
    throw error;
  }
};
