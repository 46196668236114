import React, { useState } from 'react';
import { Box, Modal, Typography } from '@mui/material';
import { useDispatch } from 'react-redux';
import EditIcon from '@mui/icons-material/Edit';
import CloseIcon from '@mui/icons-material/Close';
import { Button, GradientButton, Input } from '../../components';
import dummyImage from '../../assets/Images/Avtar.png';
import {
  AddCompanyWrapper,
  AddModelWrapper,
  ButtonWrapper,
  CloseIconWrapper,
  EditIconWrapper,
  LogoWrapper,
} from './Style/CompaniesList.style';
import { AddCompanyModalType } from '../../helpers/types';
import { addCompanyAction } from '../../store/company/action';

const AddCompanyModal = ({ open, handleClose }: AddCompanyModalType) => {
  const [logo, setLogo] = useState<string>(dummyImage);
  const [newCompanyName, setNewCompanyName] = useState<string>('');
  const [nameError, setNameError] = useState<boolean>(false);

  const dispatch = useDispatch();

  const handleFileChange = (event: any) => {
    const file = event.target.files[0];
    setLogo(URL.createObjectURL(file));
  };

  const clearLogo = () => {
    setLogo(dummyImage);
  };

  const handleCompanyName = (e: any) => {
    const companyName = e.target.value;
    setNewCompanyName(companyName);
    if (companyName.trim() !== '') {
      setNameError(false);
    } else {
      setNameError(true);
    }
  };

  const handleAddCompany = () => {
    if (newCompanyName.trim() === '') {
      setNameError(true);
      return;
    }
    dispatch(
      addCompanyAction({
        name: newCompanyName,
        vat: '',
        address: '',
        zip: '',
        phone: '',
        email: '',
        logo: '',
      }),
    );
    setNewCompanyName('');
    handleClose();
  };

  const handleEditLogo = () => {
    const fileInput = document.getElementById('logo-upload');
    if (fileInput) {
      fileInput.click();
    }
  };

  return (
    <Modal   open={open} onClose={handleClose}>
      <Box sx={AddModelWrapper}>
        <AddCompanyWrapper>
          <Typography
            variant="h6"
            component="h2"
            sx={{ fontWeight: 600, color: 'black' }}>
            Add Company
          </Typography>
          <CloseIcon onClick={handleClose} sx={{ cursor: 'pointer' }} />
        </AddCompanyWrapper>
        <hr style={{ margin: '10px' }} />
        <LogoWrapper>
          <Typography sx={{ color: 'black' }}>Logo</Typography>
          <img src={logo} alt="Logo" height={150} width={150} />
          <input
            id="logo-upload"
            type="file"
            accept="image/*"
            style={{ display: 'none' }}
            onChange={handleFileChange}
          />
          <EditIconWrapper onClick={handleEditLogo}>
            <EditIcon sx={{ cursor: 'pointer' }} />
          </EditIconWrapper>
          {logo !== dummyImage && (
            <CloseIconWrapper>
              <CloseIcon sx={{ cursor: 'pointer' }} onClick={clearLogo} />
            </CloseIconWrapper>
          )}
          <Typography variant="inherit">
            Allowed file types: png, jpg, jpeg.
          </Typography>
          <Typography sx={{ color: 'black', mt: 1.5 }}>
            Name <span style={{ color: 'red' }}>*</span>
          </Typography>
          <Input
            data-testid="new-company-name-input"
            size="small"
            required
            onChange={handleCompanyName}
            placeholder="Company Name"
            value={newCompanyName}
            error={nameError}
            helperText={nameError ? 'Please enter company name' : ''}
          />
        </LogoWrapper>
        <hr />
        <ButtonWrapper>
          <Button variant="outlined" onClick={handleClose} color="secondary">
            Cancel
          </Button>
          <GradientButton
            data-testid="submit-new-company-button"
            variant="contained"
            type="submit"
            onClick={handleAddCompany}>
            Submit
          </GradientButton>
        </ButtonWrapper>
      </Box>
    </Modal>
  );
};

export default AddCompanyModal;
