import { useState } from 'react';
import {
  Box,
  Dialog,
  DialogContent,
  DialogTitle,
  IconButton,
  Typography,
  styled,
} from '@mui/material';
import { useParams } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import CloseIcon from '@mui/icons-material/Close';
import { CloseIconStyle } from './Style/Tabs.style';
import { ButtonWrapper } from '../../CompaniesList/Style/CompaniesList.style';
import { Button, GradientButton, SelectOption } from '../../../components';
import { companyStatusList } from '../../../utils';
import { changeStatusDomainAction } from '../../../store/company/action';

const UpdateStatusDialog = styled(Dialog)(({ theme }) => ({
  '& .MuiDialogContent-root': {
    padding: theme.spacing(3, 5),
  },
  '& .MuiDialog-paper': {
    width: '550px',
  },
}));

const UpdateDomainStatusModal = ({
  handleClose,
  open,
  selectedDomain,
}: any) => {
  const [selectedStatus, setSelectedStatus] = useState<any>(
    selectedDomain.Status,
  );

  const dispatch = useDispatch();
  const { id } = useParams();

  const handleStatusChange = (e: any) => {
    setSelectedStatus(e.target.value);
  };

  const handleUpdateDomainStatus = () => {
    handleClose();
    dispatch(
      changeStatusDomainAction({
        companyId: id,
        domain: selectedDomain.Name,
        status: selectedStatus,
      }),
    );
  };

  return (
      <UpdateStatusDialog onClose={handleClose} open={open}>
        <DialogTitle sx={{ m: 0, px: 3, fontWeight: 600 }}>
          Update Protected Domain
        </DialogTitle>
        <IconButton onClick={handleClose} sx={CloseIconStyle}>
          <CloseIcon />
        </IconButton>
        <DialogContent dividers>
          <Box sx={{ display: 'flex', mt: 2 }}>
            <Typography gutterBottom sx={{ color: 'black', width: '30%' }}>
              Status *
            </Typography>
            <SelectOption
              label="status-list"
              size="small"
              onChange={handleStatusChange}
              value={selectedStatus}
              options={companyStatusList}
              width="100%"
            />
          </Box>
        </DialogContent>
        <ButtonWrapper sx={{ mt: 2 }}>
          <Button variant="outlined" onClick={handleClose} color="secondary">
            Cancel
          </Button>
          <GradientButton
            data-testid="update-domain-status-button"
            variant="contained"
            type="submit"
            onClick={handleUpdateDomainStatus}>
            Submit
          </GradientButton>
        </ButtonWrapper>
      </UpdateStatusDialog>
  );
};

export default UpdateDomainStatusModal;
